import { Subject } from "rxjs";
import { ICanTransceiveEvents, IEventSubscriber } from "../../domain/events/ICanTransceiveEvents";

export class EventPlug implements ICanTransceiveEvents {
    private _messages$: Map<string, Subject<any>>;

    constructor() {
        this._messages$ = new Map<string, Subject<any>>();
    }

    public publish(messageType: string, data: any = {}) {
        this._getSubjectForEvent(messageType).next(data);
    }

    public subscribe<T>(eventName: string, fn: (data: T) => void): IEventSubscriber {
        const subscription = this._getSubjectForEvent(eventName).subscribe(fn);// .asObservable().subscribe(fn);
        return subscription;
    }

    private _getSubject() {
        return new Subject();
    }

    private _getSubjectForEvent(eventName: string): Subject<any> {
        if (!this._messages$.has(eventName)) {
            this._messages$.set(eventName, this._getSubject());
        }
        const subject = this._messages$.get(eventName);
        if (subject === undefined) {
            throw new Error(`${eventName} not available`);
        }
        return subject;
    }
}
