
import { IArticle } from "../../domain/articles/IArticle";
import { IArticleRepository } from "../../domain/articles/IArticleRepository";
import { IIdLabel } from "../../domain/articles/IIdLabel";
import { ITaskResult } from "../../domain/core/ITaskResult";
import { appDependencies } from "../../domain/wiring/AppDependencies";
import { saveBlob } from "../../helpers/tsHelper";

export class ArticleRepository implements IArticleRepository {
    async createOrUpdateArticle(article: IArticle): Promise<ITaskResult<boolean>> {
        const response = await appDependencies.IRestApi
            .request("/api/Article")
            .data(article)
            .post();

        return response.data as ITaskResult<boolean>;
    }

    async getAll(): Promise<IArticle[]> {
        const articles = await appDependencies.IRestApi
            .request("/api/Article")
            .getData<IArticle[]>();
        return articles;
    }

    async getByNumber(articleNumber: string): Promise<IArticle> {
        const article = await appDependencies.IRestApi
            .request(`/api/Article/${articleNumber}`)
            .getData<IArticle>();
        return article;
    }

    getArticleGroups(): Promise<IIdLabel[]> {
        return appDependencies.IRestApi
            .request("/api/Article/articlegroups")
            .getData<IIdLabel[]>();
    }

    getCountries(): Promise<IIdLabel[]> {
        return appDependencies.IRestApi
            .request("/api/Article/countries")
            .getData<IIdLabel[]>();
    }

    getAlternativeItemOptions(): Promise<IIdLabel[]> {
        return appDependencies.IRestApi
            .request("/api/Article/alternativeitems")
            .getData<IIdLabel[]>();
    }

    getUnits(): Promise<IIdLabel[]> {
        return appDependencies.IRestApi
            .request("/api/Article/units")
            .getData<IIdLabel[]>();
    }

    getCurrencies(): Promise<IIdLabel[]> {
        return appDependencies.IRestApi
            .request("/api/Article/currencies")
            .getData<IIdLabel[]>();
    }

    async getArticlesAsExcel(): Promise<any> {
        const response = await appDependencies.IRestApi.request("api/Article/articlesAsExcel")
            .setResponseType("blob")
            .get();

        saveBlob(response.data, "fairplaza-articles.xlsx");
    }

    async updateArticleStock(article: IArticle, bookingValue: number): Promise<any> {
        const articleNumber = article.number;
        const vm = { articleNumber, bookingValue };

        await appDependencies.IRestApi.request("/api/Article/mutate-stock").data(vm).post();
    }
}
