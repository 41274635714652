import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { Button, Popover, Tag } from "antd";
import React, { useEffect, useState } from "react";
import { EventNames } from "../../../domain/events/EventNames";
import { translateRole } from "../../../domain/user/UserRole";
import { appDomain, appSubscribe, translate } from "../../../domain/wiring/AppDomain";

export function UserInfo() {
    const [currentUser, setCurrentUser] = useState(appDomain.IAuthenticationService.getCurrentUser());
    useEffect(() => {
        const run = async () => {
            const user = appDomain.IAuthenticationService.getCurrentUser();
            setCurrentUser(user);
        };
        const subscriptions = [
            appSubscribe(EventNames.loggedIn, run),
            appSubscribe(EventNames.loggedOut, run)
        ];
        run();
        return () => subscriptions.forEach(x => x.unsubscribe());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!currentUser) return <div className="user-info">{translate("general.loggedout")}</div>;

    // onClick={handleMenuClick}
    const content = (<>
        {currentUser.userName} <Tag color="orange">{translateRole(currentUser.userRole)}</Tag>
    </>
    );

    return <div className="user-info">
        <Popover placement="bottomRight" title={currentUser.fullName} content={content} trigger="click">
            <Button><UserOutlined /> {currentUser.fullName} <DownOutlined /></Button>
        </Popover>
    </div>;
};
