/* eslint-disable react/display-name */
import React from "react";
import { LoginView } from "../components/login/LoginView";
import { AppRoutes } from "../domain/navigators/AppRoutes";
import { ArticleView } from "../view/articleView/ArticleView";
import { BackorderView } from "../view/backorder/BackorderView";
import { SalesTransactionView } from "../view/salesTransaction/SalesTransactionView";
import { HomePageView } from "../view/home/HomePageView";
import { ExtendedRouteProps } from "./ExtendedRouteProps";

export const Routes: ExtendedRouteProps[] = [
    {
        path: AppRoutes.login,
        private: false,
        element: <LoginView />
    },
    {
        path: AppRoutes.home,
        private: true,
        element: <HomePageView />
    },
    {
        path: AppRoutes.articleView,
        private: true,
        element: <ArticleView />
    },
    {
        path: AppRoutes.backorder,
        private: true,
        element: <BackorderView />
    },
    {
        path: AppRoutes.salesTransaction,
        private: true,
        element: <SalesTransactionView />
    }
];
