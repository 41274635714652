import React, { useEffect, useState } from "react";
import { appDomain, translate } from "../../domain/wiring/AppDomain";

interface IVersionInfo {
    environment: string,
    appVersion: string,
    webApiVersion: string
}

export function VersionInfo(props: {}) {
    const [state, setState] = useState<Partial<IVersionInfo>>({
        environment: "",
        appVersion: "",
        webApiVersion: ""
    });
    function updateState(newState: Partial<IVersionInfo>) {
        setState({
            ...state,
            ...newState
        });
    }

    const init = async () => {
        const versionInfo = await appDomain.IRequestVersionInfo.requestVersionInfo();
        updateState(versionInfo);
    };

    useEffect(() => {
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="version-info">
            <span className="app">{translate("app.version")} {state.appVersion}</span><br />
            <span className="web-api">{translate("app.api-version")} {state.webApiVersion}</span>&nbsp;
            <span className="web-api-otap">({state.environment})</span>
        </div>
    );
}
