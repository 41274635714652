import React, { useEffect, useState } from "react";
import { LoadingIndicator } from "../../components/basic/LoadingIndicator/LoadingIndicator";
import { PageWrapper } from "../../components/core/PageWrapper/PageWrapper";
import { IArticle } from "../../domain/articles/IArticle";
import { AppRoutes } from "../../domain/navigators/AppRoutes";
import { appDomain, navigateTo, translate } from "../../domain/wiring/AppDomain";
import { HeaderActionBar, IActionButton } from "../home/headerbar/HeaderActionBar";
import styles from "./ArticleView.module.css";
import { ArticleForm } from "./form/ArticleForm";

interface IProps {
    params?: { articleId: string }
}

export const ArticleView = (props: IProps): JSX.Element => {
    const [article, setArticle] = useState<IArticle>({} as IArticle);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [actionButtons, setActionButtons] = useState<IActionButton[]>([]);

    const { params } = props;

    useEffect(() => {
        if (!params || !params.articleId) return;

        if (params.articleId === "create") {
            setArticle(getNewArticle());
            setLoaded(true);
            return;
        }

        const init = async () => {
            const article = await appDomain.IArticleService.getByNumber(params!.articleId);
            setArticle(article);
            setLoaded(true);
        };
        init();
    }, [params]);

    if (!params || !params.articleId) {
        navigateTo(AppRoutes.home);
        return <></>;
    }

    if (!loaded) return <LoadingIndicator isLoading={true} />;

    return <PageWrapper className={styles.articleView} pageHeaderTitle={translate("fp-home.title")} headerActionBar={<HeaderActionBar buttons={actionButtons} />}>
        <div className={styles.content}>
            <ArticleForm article={article} setActionButtons={setActionButtons} />
        </div>
    </PageWrapper>;
};

function getNewArticle(): IArticle {
    return {
        rowId: 0,
        name: "",
        number: "",
        ean: "",
        creditorNumber: "",
        alternative: "",
        useAlternative: "",
        defaultSalesQuantity: 1,
        deliveryTime: 0,
        minimalPurchaseQuantity: 0,
        purchaseQuantity: 1,
        maxStockLevel: 0,
        minStockLevel: 0,
        wareHouse: "",
        decimals: 0,
        unit: "1",
        unitName: "",
        articleGroup: "",
        purchaseCurrency: "2",
        purchasePrice: 0,
        salesPriceCurrency: "2",
        salesPrice: 0,
        costPrice: 0,
        isInactive: false,
        countryOfOrigin: "",
        tariffNumber: "",
        additionalUnit: 0,
        attachmentKeys: [],
        purchaseCurrencyText: "EUR",
        salesPriceCurrencyText: "EUR",
        inStockPhysical: 0
    };
}
