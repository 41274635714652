import { Form, Modal } from "antd";
import React from "react";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";
import { FormInputGroup } from "../FormInputGroup/FormInputGroup";
import { CustomFolderUpload } from "../../../view/articleView/upload/CustomFolderUpload";
import { EventNames } from "../../../domain/events/EventNames";

export function ImageUploaderPopup(props: { visible: boolean, hide: () => void }) {
    const [form] = Form.useForm();

    const onFinish = (values: any) => {

    };

    const onRefresh = () => {
        appDomain.IEventEmitter.emit(EventNames.uploadDone);
    };

    return <Modal title={translate("fp-article.fieldset.images") + " " + translate("import")?.toLocaleLowerCase()} visible={props.visible}
        okButtonProps={{ form: "category-editor-form", htmlType: "submit" }}
        okText={translate("ok")} onOk={() => { form.submit(); props.hide(); onRefresh(); }} cancelText={translate("cancel")} onCancel={() => { props.hide(); onRefresh(); }}>



        <Form
            name="basic"
            onFinish={onFinish}
            initialValues={{ remember: false }}
            form={form}
        >
            <FormInputGroup title={""} >
                <CustomFolderUpload/>
            </FormInputGroup>
        </Form>
    </Modal>;
}
