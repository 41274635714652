import { IRestApi, IRestApiInterceptor, RestApi, TokenAuthenticationInterceptor } from "@dna/rest-api-helper";
import { ITranslator, ITranslatorProvider, Translator } from "@dna/translator";
import { EventPlug } from "../adapters/EventAdapter/EventPlug";
import { LocalStoragePlug } from "../adapters/LocalStorageAdapter/LocalStoragePlug";
import { SessionStoragePlug } from "../adapters/LocalStorageAdapter/SessionStoragePlug";
import { NavigationPlug } from "../adapters/NavigationAdapter/NavigationPlug";
import { NotifierPlug } from "../adapters/NotifyAdapter/NotifyPlug";
import { PortResolver } from "../adapters/PortResolver";
import { ArticleRepository } from "../adapters/WebApiAdapter/ArticleRepository";
import { AuthenticationRepository } from "../adapters/WebApiAdapter/AuthenticationRepository";
import { BackorderRepository } from "../adapters/WebApiAdapter/BackorderRepository";
import { VersionInfoRepository } from "../adapters/WebApiAdapter/VersionInfoRepository";
import { AlertService } from "../domain/alerts/AlertService";
import { IRequestAlert } from "../domain/alerts/IRequestAlert";
import { ArticleService } from "../domain/articles/ArticleService";
import { IArticleRepository } from "../domain/articles/IArticleRepository";
import { IArticleService } from "../domain/articles/IArticleService";
import { AuthenticationService } from "../domain/authentication/AuthenticationService";
import { IAuthenticationRepository } from "../domain/authentication/IAuthenticationRepository";
import { IAuthenticationService } from "../domain/authentication/IAuthenticationService";
import { IRequestPasswordChange } from "../domain/authentication/IRequestPasswordChange";
import { RequestPasswordChange } from "../domain/authentication/RequestPasswordChange";
import { BackorderService } from "../domain/backorders/BackorderService";
import { IBackorderRepository } from "../domain/backorders/IBackorderRepository";
import { IBackorderService } from "../domain/backorders/IBackorderService";
import { SalesTransactionService } from "../domain/salesTransactions/SalesTransactionService";
import { ISalesTransactionRepository } from "../domain/salesTransactions/ISalesTransactionRepository";
import { ISalesTransactionService } from "../domain/salesTransactions/ISalesTransactionService";
import { EventService } from "../domain/events/EventService";
import { ICanTransceiveEvents } from "../domain/events/ICanTransceiveEvents";
import { IEventEmitter } from "../domain/events/IEventEmitter";
import { IEventHandler } from "../domain/events/IEventHandler";
import { ExceptionService } from "../domain/exceptions/ExceptionService";
import { IHandleExceptions } from "../domain/exceptions/IHandleExceptions";
import { INavigateTo } from "../domain/navigators/INavigateTo";
import { INavigator } from "../domain/navigators/INavigator";
import { Navigator } from "../domain/navigators/Navigator";
import { ICanNotify } from "../domain/notifiers/ICanNotify";
import { IRequestNotify } from "../domain/notifiers/IRequestNotify";
import { NotifyService } from "../domain/notifiers/NotifyService";
import { ISearchService } from "../domain/search/ISearchService";
import { SearchService } from "../domain/search/SearchService";
import { ILocalRepository } from "../domain/storages/ILocalRepository";
import { IStateService } from "../domain/storages/IStateService";
import { StateService } from "../domain/storages/StateService";
import { IRequestTranslation } from "../domain/translations/IRequestTranslation";
import { TranslationService } from "../domain/translations/TranslationService";
import { IRequestVersionInfo } from "../domain/versions/IRequestVersionInfo";
import { IVersionInfoRepository } from "../domain/versions/IVersionInfoRepository";
import { VersionService } from "../domain/versions/VersionService";
import { appDependencies } from "../domain/wiring/AppDependencies";
import { appDomain } from "../domain/wiring/AppDomain";
import { SalesTransactionRepository } from "../adapters/WebApiAdapter/SalesTransactionRepository";

export class Wiring {
    portResolver: PortResolver;

    constructor() {
        this.portResolver = new PortResolver();
        appDomain.setPortResolver(this.portResolver);
        appDependencies.setPortResolver(this.portResolver);

        const instance = this.portResolver.registerInstance.bind(this.portResolver) as <T>(name: string, fn: () => T) => void;

        this.setPrimaryPorts(instance);
        this.setSecondaryPorts(instance);
    }

    public registerInstance<T>(name: string, fn: () => T) {
        return this.portResolver.registerInstance<T>(name, fn);
    }

    private setPrimaryPorts(instance: <T>(name: string, fn: () => T) => void) {
        instance<IHandleExceptions>("IHandleExceptions", () => new ExceptionService());
        instance<INavigator>("INavigator", () => new Navigator());
        instance<IEventHandler>("IEventHandler", () => new EventService());
        instance<IEventEmitter>("IEventEmitter", () => new EventService());
        instance<IRequestVersionInfo>("IRequestVersionInfo", () => new VersionService());
        instance<IRequestAlert>("IRequestAlert", () => new AlertService());
        instance<IRequestTranslation>("IRequestTranslation", () => new TranslationService());
        instance<IRequestNotify>("IRequestNotify", () => new NotifyService());
        instance<IStateService>("IStateService", () => new StateService());
        instance<IAuthenticationService>("IAuthenticationService", () => new AuthenticationService());
        instance<ISearchService>("ISearchService", () => new SearchService());
        instance<IArticleService>("IArticleService", () => new ArticleService());
        instance<IBackorderService>("IBackorderService", () => new BackorderService());
        instance<ISalesTransactionService>("ISalesTransactionService", () => new SalesTransactionService());
        instance<IRequestPasswordChange>("IRequestPasswordChange", () => new RequestPasswordChange());
    }

    private async setSecondaryPorts(instance: <T>(name: string, fn: () => T) => void) {
        const interceptor: IRestApiInterceptor = new TokenAuthenticationInterceptor(new AuthenticationService());
        instance<IAuthenticationRepository>("IAuthenticationRepository", () => new AuthenticationRepository());

        instance<IRestApi>("IRestApi", () => new RestApi((window as any).config.WebApiUrl, interceptor, this.portResolver.resolve("IHandleExceptions")));
        instance<IRestApi>("IRestApiWithoutUrl", () => new RestApi("", interceptor, this.portResolver.resolve("IHandleExceptions")));

        instance<INavigateTo>("INavigateTo", () => new NavigationPlug(() => { throw new Error("Not loaded"); }));
        instance<ICanNotify>("ICanNotify", () => new NotifierPlug());
        instance<ICanTransceiveEvents>("ICanTransceiveEvents", () => new EventPlug());
        instance<IVersionInfoRepository>("IVersionInfoRepository", () => new VersionInfoRepository());
        instance<ILocalRepository>("ISessionRepository", () => new SessionStoragePlug());
        instance<ILocalRepository>("ILocalRepository", () => new LocalStoragePlug());

        instance<IArticleRepository>("IArticleRepository", () => new ArticleRepository());
        instance<IBackorderRepository>("IBackorderRepository", () => new BackorderRepository());
        instance<ISalesTransactionRepository>("ISalesTransactionRepository", () => new SalesTransactionRepository());

        const translatorProvider: ITranslatorProvider = this.portResolver.resolve("IRequestTranslation");
        instance<ITranslator>("ITranslator", () => new Translator(translatorProvider));
    }
}
