import { ValidationResult } from "./ValidationResult";

export class ValidationError {
    validationResults: Array<ValidationResult>;

    constructor(validationResults: Array<ValidationResult>) {
        // super("Validation failed");
        Object.setPrototypeOf(this, ValidationError.prototype);
        this.validationResults = validationResults;
    }
}
