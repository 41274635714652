/* eslint-disable react-hooks/exhaustive-deps */

import { AnimatePresence } from "framer-motion";
import React, { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router";
import { NavigationPlug } from "./adapters/NavigationAdapter/NavigationPlug";
import { wiring } from "./index";
import { AppRoutes } from "./domain/navigators/AppRoutes";
import { INavigateTo } from "./domain/navigators/INavigateTo";
import { appDomain } from "./domain/wiring/AppDomain";
import { setLocationState } from "./helpers/tsHelper";
import { RenderRoute } from "./RenderRoute";
import { Routes } from "./routes/Routes";

let pageId = 1;

export function RoutingWrapper(props: {}): JSX.Element {
    const location = useLocation();
    const navigate = useNavigate();

    const [activeRoutes, setActiveRoutes] = useState<JSX.Element[]>([]);
    const [currentPath, setCurrentPath] = useState<string>();

    useEffect(() => {
        const navigateFn = (path: string, state?: any) => {
            setLocationState(path, state);
            navigate(path);
        };
        wiring.registerInstance<INavigateTo>("INavigateTo", () => new NavigationPlug(navigateFn));
    }, []);

    useEffect(() => {
        if (location.pathname === currentPath) return;

        const allRoutes = Array.from(Routes);
        const route = allRoutes.find(r => matchPath(String(r.path), location.pathname));
        if (route) {
            setCurrentPath(String(route.path));
            pageId = (pageId + 1) % 100;
            const key = `route-${pageId}`;
            setActiveRoutes([<RenderRoute key={key} id={key} route={route} pathName={location.pathname} />]);
            return;
        }
        appDomain.INavigator.navigateTo(AppRoutes.home);
    }, [location]);

    return <AnimatePresence>{activeRoutes}</AnimatePresence>;
}
