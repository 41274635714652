import { EventNames } from "../events/EventNames";
import { appDependencies } from "../wiring/AppDependencies";
import { appEmit } from "../wiring/AppDomain";
import { ISearchService } from "./ISearchService";

export class SearchService implements ISearchService {
    setSearchText(searchText: string): void {
        appDependencies.ISessionRepository.storeSync("search", searchText);
        appEmit(EventNames.search, searchText);
    }

    getSearchText(): string {
        return appDependencies.ISessionRepository.getSync<string>("search") || "";
    }
}
