import React from "react";
import { AppRoutes } from "../../domain/navigators/AppRoutes";
import { appDomain, navigateTo } from "../../domain/wiring/AppDomain";
import { AppLogo } from "../core/AppLogo";
import { LoginForm } from "./LoginForm";
import styles from "./LoginView.module.css";

export function LoginView() {
    if (appDomain.IAuthenticationService.isAuthenticated()) {
        navigateTo(AppRoutes.home);
    }

    return <div className={styles.viewLogin}>
        <div className={styles.viewLoginHeader}>
            <AppLogo className={styles.appLogo} />
        </div>
        <LoginForm />
        <div className="footer">
        </div>
    </div>;
}
