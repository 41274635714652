import { EventNames } from "../events/EventNames";
import { appDependencies } from "../wiring/AppDependencies";
import { appEmit } from "../wiring/AppDomain";
import { INavigator } from "./INavigator";

export class Navigator implements INavigator {
    navigateBack(): void {
        appDependencies.INavigateTo.goBack();
    }

    navigateTo(url: string, params?: any) {
        appEmit(EventNames.navigate, url);
        appDependencies.INavigateTo.go(url, params);
    }

    popUpTo(popUp: Function): void {
        popUp(true);
    }

    reload() {
        appDependencies.INavigateTo.reload();
    }
}
