import Search from "antd/lib/input/Search";
import React, { useCallback, useEffect, useState } from "react";
import { LoadingIndicator } from "../../../components/basic/LoadingIndicator/LoadingIndicator";
import { IArticle } from "../../../domain/articles/IArticle";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";
import { delay, stringIsNullOrEmpty, moneyFormat } from "../../../helpers/tsHelper";
import { ArticleBlock } from "../block/ArticleBlock";
import { AppRoutes, navigateToWithParams } from "../../../domain/navigators/AppRoutes";
import { Checkbox, Pagination, Table } from "antd";
import styles from "./ArticleList.module.css";
import Column from "antd/lib/table/Column";
import { numericLiteral } from "@babel/types";
import { GetLocalState } from "../../../helpers/LocalStorageHelper";
import { ColumnsType } from "antd/lib/table";

const { forwardRef, useRef, useImperativeHandle } = React;

interface IProps {
    useList: boolean;
    filterInactive: boolean;
}

interface IState {
    pageIndex: number,
    minValue: number,
    maxValue: number
    pageSize: number
}

export const ArticleList = (props: IProps) => {
    const [articles, setArticles] = useState<IArticle[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");

    const [pageIndex, setPageIndex] = useState(() => {
        return Number(GetLocalState("getPageIndex", "1"));
    });

    const numEachPage = 20;
    const [state, setState] = useState<IState>({
        minValue: (Number(pageIndex) - 1) * numEachPage,
        maxValue: (Number(pageIndex) - 1) * numEachPage + numEachPage,
        pageIndex: Number(pageIndex),
        pageSize: numEachPage
    });

    useEffect(() => {
        state.pageIndex = Number(pageIndex);

        const init = async () => {
            const data = await appDomain.IArticleService.getAll();
            setArticles(data);
            setLoaded(true);
        };
        init();
    }, []);

    if (!loaded) return <div> <LoadingIndicator isLoading={true}/> </div>;

    if (!articles.some(x => !!x) && loaded) { return <div className={styles.noItemFound}>{translate("fp-noItem")}</div>; };

    const onSearch = (value: string) => setSearchText(value);

    const delaySearch = async (value: string) => {
        await delay(500);
        setSearchText(value);
    };

    function getViewmodel(article: IArticle) {
        var view: any = {
            number: article.number,
            name: article.name,
            ean: article.ean,
            salesPrice: moneyFormat(article.salesPrice, article.salesPriceCurrencyText),
            unitName: translate(article.unitName?.toLocaleLowerCase()),
            isInactive: article.isInactive
        };
        return view;
    }

    function handleChange(index: any, pageSize: any) {
        setState({
            minValue: (index - 1) * pageSize,
            maxValue: index * pageSize,
            pageIndex: index,
            pageSize: pageSize
        });

        localStorage.setItem("getPageIndex", JSON.stringify(index));

        setPageIndex(index);
        document.getElementById("yourDivID")?.scrollIntoView();
    }

    function getArticles() {
        if (!props.useList) {
            return <div>
                <div className={styles.articleList}>
                    {foundArticles.slice(state.minValue, state.maxValue).map((x: IArticle) => <ArticleBlock key={`article-${x.number}`} article={x} />)}
                </div>
                <div className={styles.paginationCover}>
                    <Pagination pageSize={ state.pageSize } current={ state.pageIndex } total={ foundArticles.length } onChange={ handleChange } showQuickJumper showTotal={(total, range) => `${range[0]}-${range[1]} / ${total} ${translate("inventoryItems")}`}/>
                </div>            </div>;
        } else {
            const datasource = foundArticles.map(getViewmodel);
            const columns: ColumnsType<IArticle> = [
                {
                    title: translate("itemNumber"),
                    dataIndex: "number",
                    key: "number"
                },
                {
                    title: translate("description"),
                    dataIndex: "name",
                    key: "name"
                },
                {
                    title: translate("ean"),
                    dataIndex: "ean",
                    key: "ean"
                },
                {
                    title: translate("salesPrice"),
                    dataIndex: "salesPrice",
                    key: "salesPrice"
                },
                {
                    title: translate("unit"),
                    dataIndex: "unitName",
                    key: "unitName"
                },
                {
                    title: translate("inActive"),
                    dataIndex: "isInactive",
                    key: "isInactive",
                    render: (dataIndex) => (
                        <Checkbox checked={dataIndex} />
                    )
                }
            ];

            return <Table className={ styles.nestedRow } dataSource={ datasource } columns={ columns } size="small" bordered={ true }
                onRow={(record, rowIndex) => {
                    return {
                        onClick: (event) => {
                            return navigateToWithParams(AppRoutes.articleView, record.number);
                        }

                    };
                }}
            />;
        }
    }
    let foundArticles = !props.filterInactive ? articles : articles.filter(article => !article.isInactive);

    if (!stringIsNullOrEmpty(searchText)) {
        const searchWords = searchText.toLowerCase().split(" ");
        foundArticles = articles.filter((a: { name: any; costPrice: any; salesPrice: any; unit: any; number: any; ean: any; }) => {
            const articleText = [a.name, a.costPrice, a.salesPrice, a.unit, a.number, a.ean].join(" ").toLowerCase();
            return searchWords.every((y: string) => articleText.includes(y));
        });
    }

    return <div id="yourDivID">
        <Search className={styles.searchBar} placeholder={translate("searchText")} onChange={(e) => delaySearch(e.target.value)} onSearch={onSearch} enterButton allowClear />
        {getArticles()}
    </div>;
};
