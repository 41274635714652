import React, { useEffect } from "react";
import { Footer } from "antd/lib/layout/layout";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./PageWrapper.css";
import { PageWrapperHeader } from "./PageWrapperHeader";
import { AutoComplete } from "antd";
import { translate } from "../../../domain/wiring/AppDomain";

export interface PageWrapperProps {
    pageHeaderTitle?: string;
    headerActionBar?: JSX.Element[] | JSX.Element;
    children: JSX.Element[] | JSX.Element;
    className?: string;
}

export const PageWrapper = (props: PageWrapperProps) => {
    const { pageHeaderTitle, className, children } = props;
    const classNames = ["page-wrapper"];

    if (pageHeaderTitle) {
        classNames.push("with-header");
    }

    if (className) {
        classNames.push(className);
    }

    const childArray = Array.isArray(children) ? children : [children];
    let content = <></>;
    let footer = <></>;

    if (childArray.length > 0) {
        content = <PerfectScrollbar>
            {childArray[0]}
        </PerfectScrollbar>;
    }

    if (childArray.length > 1) {
        footer = <Footer style={{ textAlign: "right" }}>{childArray[1]}</Footer>;
    }

    return (
        <div className={classNames.join(" ")}>
            <PageWrapperHeader {...props} />
                {content}
            {footer}
        </div>
    );
};
