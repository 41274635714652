import { Select } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import React, { useEffect, useState } from "react";
import { IIdLabel } from "../../../../domain/articles/IIdLabel";
import { appDomain, translate } from "../../../../domain/wiring/AppDomain";

interface IProps {
    value?: string
    onChange?: (s: string) => any;
}

export function UnitSelector(props: IProps) {
    const [units, setUnits] = useState<IIdLabel[]>([]);

    useEffect(() => {
        const init = async () => {
            const data = await appDomain.IArticleService.getUnits();
            setUnits(data.map(x => ({ id: x.id, label: translate(x.label?.toLocaleLowerCase()) } as IIdLabel)));
        };
        init();
    }, []);

    const options: DefaultOptionType[] = units.map(x => ({ label: x.label, value: x.id }));

    return <Select
        showSearch
        autoFocus
        placeholder={translate("select")}
        optionFilterProp="children"
        filterOption={(input, option) => String(option!.label).toLowerCase().indexOf(input.toLowerCase()) >= 0}
        filterSort={(optionA, optionB) => String(optionA.label).toLowerCase().localeCompare(String(optionB.label).toLowerCase())}
        options={options}
        {...props}
    >
    </Select >;
}
