import { Button, Form, Input, List, Modal, Table, Upload, UploadProps, Spin } from "antd";
import { appDependencies } from "../../../domain/wiring/AppDependencies";
import React, { useEffect, useState } from "react";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";
import { UploadOutlined } from "@ant-design/icons";
import { getImportUrlFromExcel } from "../../../helpers/excelHelper";
import { UploadFile } from "antd/lib/upload/interface";
import { ArticleExcelValidation, ExcelValidationInterface, ExcelValidationLine } from "./validation.model";

import styles from "./ImportExportExcel.module.css";
import { EventNames } from "../../../domain/events/EventNames";
import { OnlyWhen } from "../DeleteAction";

export function ImportExportExcelPopup(props: { visible: boolean, hide: () => void }) {
    const [form] = Form.useForm();

    const [validationsResult, setValidationResult] = useState<ExcelValidationLine[]>();

    const titleWindow = translate("fp-import.export.excel");

    const [token, setToken] = useState("");
    const [uploadTried, setUploadTried] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        const load = async () => setToken(await appDomain.IAuthenticationService.getToken());

        load();
    }, []);

    const headers = { Authorization: `Bearer ${token}` };

    const exportToExcel = async () => {
        setLoading(true);
        await appDependencies.IArticleRepository.getArticlesAsExcel().then(() => {
            setLoading(false);
        });
    };

    const importFromExcel = () => {
        return getImportUrlFromExcel();
    };

    const handleChange = (file: any, fileList: UploadFile<any>[]) => {
        if (file.status !== "uploading") {
            var validationResults: ArticleExcelValidation = file.response.result as ArticleExcelValidation;
            var listLines = Array<ExcelValidationLine>();

            if (validationResults.validations.length === 0) {
                showModel("fp-import.export.excel.succes");
                return;
            }

            validationResults.validations.forEach((item) => {
                listLines.push({
                    row: item.row,
                    message: translate("missing") + ": " + item.validations.map((x) => x.message + " ")
                });
            });

            setValidationResult(listLines);

            if (validationResults.onceSucceed) { showModel("fp-import.export.excel.fail"); }
        }
    };

    const columns = [
        {
            title: translate("row"),
            width: 5,
            dataIndex: "row",
            key: "row"
        },
        {
            title: translate("messageType"),
            width: 300,
            dataIndex: "message",
            key: "message"
        }
    ];

    const propsUpload: UploadProps = {
        name: translate("import"),
        beforeUpload: file => {
            const isXLSX = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (!isXLSX) {
                return false;
            }
            return isXLSX || Upload.LIST_IGNORE;
        },
        headers: headers,
        action: importFromExcel(),
        showUploadList: false,
        onChange({ file, fileList }) {
            setUploadTried(true);
            setValidationResult([]);
            if (file.status == "uploading") {
                setLoading(true);
            } else {
                setLoading(false);
            }
            if (file.status !== "uploading") {
                handleChange(file, fileList);
            }
        }
    };

    const onFinish = (values: any) => {
        //  moet hier naar gekeken worden voor cleaner fix...
        window.location.reload();
    };

    const onRefresh = () => {
        if (uploadTried) { appDomain.IEventEmitter.emit(EventNames.uploadDone); }
    };

    const onDestroy = () => {
        setValidationResult([]);
    };

    function showModel(message: string) {
        return Modal.success({
            title: translate(message)
        });
    }

    return <Modal title={titleWindow} visible={props.visible} maskClosable={false}
        okButtonProps={{ form: "category-editor-form", htmlType: "submit" }} afterClose={() => onDestroy()}
        okText={translate("ok")} onOk={() => { form.submit(); props.hide(); onRefresh(); }} cancelText={translate("cancel")} onCancel={() => { props.hide(); onRefresh(); }}>

        <Form
            className={styles.form}
            name="basic"
            onFinish={onFinish}
            initialValues={{ remember: false }}
            form={form}
        >
            {OnlyWhen(loading, () => <div className={styles.spinner}><Spin size="large" tip="loading..."></Spin></div>)}
            <Button className={styles.buttonLeft} onClick={() => exportToExcel()}>
                {translate("export")}
            </Button>

            <Upload className={styles.buttonRight} {...propsUpload} >
                <Button icon={<UploadOutlined />}>{translate("import")}</Button>
            </Upload>

            <br /> <br />
            <div style={{ overflow: "auto", maxHeight: "300px" }}>
                <Table className={styles.tableWidth} dataSource={validationsResult} columns={columns} />
            </div>
        </Form>
    </Modal>;
}
