import { appDependencies } from "../wiring/AppDependencies";
import { IRequestAlert } from "./IRequestAlert";

export class AlertService implements IRequestAlert {
    successfullSaved(): Promise<any> {
        return this.showSucces("successfully-saved");
    }

    showSucces(message: string): Promise<any> {
        return appDependencies.ICanShowAlert.success(appDependencies.ICanTranslate.translate(message));
    }

    async askQuestion(title: string, message: string): Promise<boolean> {
        const result = await appDependencies.ICanShowAlert.question(appDependencies.ICanTranslate.translate(title), appDependencies.ICanTranslate.translate(message));
        const cancel = result && result.dismiss && result.dismiss === "cancel";

        return !cancel;
    }

    showJsonInfo(jsonString: any) {
        return appDependencies.ICanShowAlert.debugInfo("", JSON.stringify(jsonString, undefined, 4));
    }

    showInfo(str: any) {
        return appDependencies.ICanShowAlert.debugInfo("", String(str));
    }
}
