
export const webApiUrl = (window as any).config.WebApiUrl.replace(/\/$/g, "");
export const getArticleImageUrl = (articleId: number, key: string) => {
    return `${webApiUrl}/api/article/image/${articleId}/${key}`;
};

export const getArticleImageAddUrl = (articleId: number) => {
    return webApiUrl + `/api/article/image/${articleId}`;
};

export const getImagesAddUrl = () => {
    return webApiUrl + "/api/article/images";
};
