import { IRequestAlert } from "../alerts/IRequestAlert";
import { IArticleService } from "../articles/IArticleService";
import { IAuthenticationService } from "../authentication/IAuthenticationService";
import { IRequestPasswordChange } from "../authentication/IRequestPasswordChange";
import { IBackorderService } from "../backorders/IBackorderService";
import { EventNames } from "../events/EventNames";
import { IEventEmitter } from "../events/IEventEmitter";
import { IEventHandler, IEventSubscription } from "../events/IEventHandler";
import { IHandleExceptions } from "../exceptions/IHandleExceptions";
import { INavigator } from "../navigators/INavigator";
import { IRequestNotify } from "../notifiers/IRequestNotify";
import { ISalesTransactionService } from "../salesTransactions/ISalesTransactionService";
import { ISearchService } from "../search/ISearchService";
import { IStateService } from "../storages/IStateService";
import { IRequestTranslation } from "../translations/IRequestTranslation";
import { IRequestVersionInfo } from "../versions/IRequestVersionInfo";
import { BaseResolver } from "./BaseResolver";

// tslint:disable:naming-convention
class AppDomain extends BaseResolver {
    get IHandleExceptions() { return this.resolve<IHandleExceptions>("IHandleExceptions"); }
    get INavigator() { return this.resolve<INavigator>("INavigator"); }
    get IEventHandler() { return this.resolve<IEventHandler>("IEventHandler"); }
    get IEventEmitter() { return this.resolve<IEventEmitter>("IEventEmitter"); }
    get IRequestVersionInfo() { return this.resolve<IRequestVersionInfo>("IRequestVersionInfo"); }
    get IRequestAlert() { return this.resolve<IRequestAlert>("IRequestAlert"); }
    get IRequestTranslation() { return this.resolve<IRequestTranslation>("IRequestTranslation"); }
    get IRequestNotify() { return this.resolve<IRequestNotify>("IRequestNotify"); }
    get IStateService() { return this.resolve<IStateService>("IStateService"); }
    get IAuthenticationService() { return this.resolve<IAuthenticationService>("IAuthenticationService"); }
    get ISearchService() { return this.resolve<ISearchService>("ISearchService"); }
    get IArticleService() { return this.resolve<IArticleService>("IArticleService"); }
    get IBackorderService() { return this.resolve<IBackorderService>("IBackorderService"); }
    get ISalesTransactionService() { return this.resolve<ISalesTransactionService>("ISalesTransactionService"); }
    get IRequestPasswordChange() { return this.resolve<IRequestPasswordChange>("IRequestPasswordChange"); }
}

export const appDomain = new AppDomain();

export const translate = (translationKey: string, obj?: {}): string => appDomain.IRequestTranslation.translate(translationKey, obj);
export const navigateTo = (url: string, params?: any): void => appDomain.INavigator.navigateTo(url, params);
export const popUpTo = (popUp: Function): void => appDomain.INavigator.popUpTo(popUp);
export const navigateBack = (): void => appDomain.INavigator.navigateBack();

export const appEmit = (eventName: EventNames, data?: any) => appDomain.IEventEmitter.emit(eventName, data);
export const appSubscribe = <T>(eventName: string, fn: (data: T) => void): IEventSubscription => appDomain.IEventHandler.subscribe(eventName, fn);
