import { PlusOutlined } from "@ant-design/icons";
import { Button, Empty, message, Modal, Spin, Table, Upload } from "antd";
import { ColumnsType } from "antd/lib/table";
import { RcFile, UploadFile, UploadProps } from "antd/lib/upload/interface";
import te from "date-fns/esm/locale/te/index.js";
import { fi } from "date-fns/locale";
import React, { useEffect, useState } from "react";
import { ExcelValidationLine } from "../../../components/core/ImportExportExcel/validation.model";
import { appDependencies } from "../../../domain/wiring/AppDependencies";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";
import { getImagesAddUrl } from "../../../helpers/imageHelpers";
import { useAsyncFn } from "../../../helpers/useAsyncFn";
import { UploadedFile } from "./file.model";
import { ImageValidationLine, ImageValidationMessage } from "./ImageValidation";

import styles from "./CustomUpload.module.css";

function getBase64(file: RcFile): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(String(reader.result));
        reader.onerror = error => reject(error);
    });
}

interface IProps {
}

export function CustomFolderUpload(props: IProps) {
    const [loading, setLoading] = useState<boolean>(false);

    const [validationsResult, setValidationResult] = useState<ImageValidationLine[]>();

    const columns = [
        {
            title: translate("name"),
            width: 150,
            dataIndex: "imageName",
            key: "imageName"
        },
        {
            title: translate("messageType"),
            width: 300,
            dataIndex: "message",
            key: "message"
        }
    ];

    const [token, setToken] = useState("");

    useEffect(() => {
        const load = async () => setToken(await appDomain.IAuthenticationService.getToken());

        load();
    }, []);

    const headers = { Authorization: `Bearer ${token}` };

    const handlePreview = async (file: UploadFile<File>) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj!);
        }
        const title = file.name || String(file.url).substring(String(file.url).lastIndexOf("/") + 1);
    };

    const handleChange = (file: any, fileList: UploadFile<any>[]) => {
        if (file.status !== "uploading") {
            setLoading(true);

            var validationResult: ImageValidationMessage = file.response.result as ImageValidationMessage;

            var listLines = validationsResult?.map((item, index) => { return { row: index + 1, imageName: item.imageName, message: item.message }; });

            if (listLines !== undefined) {
                listLines.push({
                    row: listLines.length + 1,
                    imageName: validationResult.imageName,
                    message: translate(validationResult.message)
                });
            }
            setValidationResult(listLines);
        } else {
            setLoading(true);
        }
    };

    const beforeUpload = (file: RcFile) => {
        const acceptingFileTypes = ["image/jpeg", "image/png", "image/webp", "image/gif", "image/avif", "image/apng"];
        const isValidImage = !!acceptingFileTypes.find(x => x === file.type);
        if (!isValidImage) {
            message.error("Invalid image type!");
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error("Image must smaller than 10MB!");
        }
        return isValidImage && isLt2M;
    };
    const onRemove = async (e: UploadFile<File>, articleId: number) => {
        const confirmResponse = appDomain.IRequestNotify.notify({
            title: translate("remove") + "?",
            showConfirmButton: true,
            confirmButtonText: translate("yes"),
            cancelButtonText: translate("no"),
            showCancelButton: true
        });
        if ((await confirmResponse).isConfirmed) {
            const response = await appDependencies.IRestApiWithoutUrl.request(`${e.url}`).delete();
            return response.status === 204;
        }
        return false;
    };

    const uploadImagesGlobarOrPerArticle = () => {
        return getImagesAddUrl();
    };

    const propsUpload: UploadProps = {
        multiple: true,
        headers: headers,
        action: uploadImagesGlobarOrPerArticle(),
        beforeUpload: file => { beforeUpload(file); },
        showUploadList: false,
        onChange({ file, fileList }) {
            setValidationResult([]);

            if (file.status === "uploading") {
                setLoading(true);
            }

            if (file.status !== "uploading") {
                handleChange(file, fileList);
                setLoading(false);
            }
        }
    };

    const uploadButton = (
        <Button>{translate("fp-select.image")}</Button>
    );

    const spinning = () => {
        if (loading) {
            return <Spin style={{ marginLeft: "10px" }}/>;
        }
    };

    return <div>

        <Upload {...propsUpload}
        >
            {uploadButton}

            {spinning()}

        </Upload>
        <div style={{ overflow: "auto", maxHeight: "300px" }}>
            <Table className={styles.tableWidth} dataSource={validationsResult} columns={columns} />
        </div>
    </div>;
}
