import { IRestApi } from "interfaces/IRestApi";
import { ITokenProvider } from "interfaces/ITokenProvider";
// eslint-disable-next-line camelcase
import jwt_decode from "jwt-decode";
import { ITaskResult } from "../core/ITaskResult";
import { EventNames } from "../events/EventNames";
import { AppRoutes } from "../navigators/AppRoutes";
import { LocalStorageKeys } from "../storages/LocalStorageKeys";
import { UserRole } from "../user/UserRole";
import { ValidationResults } from "../validation/ValidationResults";
import { appDependencies } from "../wiring/AppDependencies";
import { appDomain, translate } from "../wiring/AppDomain";
import { IAuthenticationService } from "./IAuthenticationService";
import { IClaims } from "./IClaims";
import { ICurrentUser } from "./ICurrentUser";
import { IToken } from "./IToken";

export class AuthenticationService implements IAuthenticationService, ITokenProvider {
    private isRequestingToken: boolean = false;

    logoff(): void {
        appDependencies.ILocalRepository.delete(LocalStorageKeys.authToken);
        appDomain.IEventEmitter.emit(EventNames.loggedOut);
        appDependencies.INavigateTo.go(AppRoutes.login);
    }

    redirectToLogin(targetUri: string): Promise<any> {
        appDependencies.INavigateTo.go(AppRoutes.login);
        return Promise.resolve();
    }

    isAuthenticated(): boolean {
        const claims = this.getClaims();
        if (claims && claims.sub) return true;
        return false;
    }

    async invalidateToken(api: IRestApi): Promise<void> {
        const token = await this.loadToken();
        if (token && token.token && !this.isRequestingToken) {
            this.isRequestingToken = true;
            try {
                const newToken = await appDependencies.IAuthenticationRepository.refreshToken(token.token);
                if (newToken && newToken.token) {
                    await this.saveToken(newToken);
                    window.location.reload();
                }
            } catch {
                // refresh token failed, logoff
                this.logoff();
            } finally {
                this.isRequestingToken = false;
            }
        }
    }

    async login(username: string, password: string): Promise<ITaskResult<IToken>> {
        const validationResults = new ValidationResults();
        if (!username) {
            validationResults.addResult("username", translate("general.cannot.be.empty"));
        }
        if (!password) {
            validationResults.addResult("password", translate("general.cannot.be.empty"));
        }
        validationResults.throwIfNotValid();

        const task = await appDependencies.IAuthenticationRepository.getToken(username, password);

        if (task.success) {
            const token = task.result;
            this.saveToken(token);
            if (await this.isAuthenticated()) {
                appDomain.IEventEmitter.emit(EventNames.loggedIn);
            }
        }
        return task;
    }

    async requestPasswordChange(oldPassword: string, newPassword: string, confirmPassword: string) {
        const validationResults = new ValidationResults();
        if (newPassword.length < 6) {
            // validationResults.addTranslationResult("uo-pass-nenough-chars", "newPass1");
        }

        if (newPassword !== confirmPassword) {
            // validationResults.addTranslationResult("uo-pass-mbesame-asconfim", "newPass2");
        }

        validationResults.throwIfNotValid();
        // return appDependencies.IAuthenticationRepository.changeUserPassword(oldPassword, newPassword);
    }

    getCurrentUser(): ICurrentUser | undefined {
        const claims = this.getClaims();
        if (claims && claims.sub && claims.UserRole) {
            return {
                userName: claims.sub,
                fullName: claims.fullName,
                imageUpload: claims.imageUpload,
                importExportExcel: claims.importExportExcel,
                purchaseOverview: claims.purchaseOverview,
                salesOverview: claims.salesOverview,
                userRole: parseInt(claims.UserRole) as UserRole,
                claims: claims
            };
        }
        return undefined;
    }

    getClaims(): IClaims {
        const token = this.loadToken();
        if (token && token.token) {
            return jwt_decode(token.token) as IClaims;
        }
        return {} as IClaims;
    }

    getUserRole(): UserRole {
        const claims = this.getClaims();
        if (claims && claims.UserRole) {
            return parseInt(claims.UserRole) as UserRole;
        }
        return UserRole.undefined;
    }

    async getToken(): Promise<string> {
        const token = this.loadToken();
        return token?.token ?? "";
    }

    private async saveToken(token: IToken): Promise<boolean> {
        if (token && token.token) {
            await appDependencies.ILocalRepository.store(LocalStorageKeys.authToken, token);
            return true;
        } else {
            this.logoff();
            return false;
        }
    }

    private loadToken(): IToken {
        return appDependencies.ILocalRepository.getSync<IToken>(LocalStorageKeys.authToken);
    }
}
