import { RuleTester } from "eslint";
import { ITaskResult } from "../core/ITaskResult";
import { ValidationResults } from "../validation/ValidationResults";
import { appDependencies } from "../wiring/AppDependencies";
import { IRequestPasswordChange } from "./IRequestPasswordChange";
import { IToken } from "./IToken";

export class RequestPasswordChange implements IRequestPasswordChange {
    async requestPasswordChange(oldPassword: string, newPassword: string, confirmPassword: string): Promise<boolean> {
        const validationResults = new ValidationResults();
        if (newPassword.length < 6) {
            validationResults.addTranslationResult("uo-pass-nenough-chars", "forgotPassword");
        }

        if (newPassword !== confirmPassword) {
            validationResults.addTranslationResult("uo-pass-mbesame-asconfim", "forgotPassword");
        }

        validationResults.throwIfNotValid();
        var result = await appDependencies.IRestApi.request("api/user/change-password").data({
            CurrentPassword: oldPassword,
            NewPassword: newPassword
        }).post();

        var passwordChangeSucces = result.data as ITaskResult<IToken>;
        if (passwordChangeSucces.validationResults) {
            return false;
        }
        return true;
    }
}
