import { format } from "date-fns";
import { enUS, fr, nl } from "date-fns/locale";

const getDaysOfMonth = (input: Date) => {
    const date = new Date(input);
    date.setDate(1);
    const month = date.getMonth();
    const days = [];
    while (date.getMonth() === month) {
        days.push(new Date(date));
        date.setDate(date.getDate() + 1);
    }
    return days;
};

const datesEqual = (a: Date, b: Date) => a.getDate() === b.getDate() && a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();

function dateAdd(date: Date | null | undefined, ms: number): Date {
    date = date || new Date();
    return new Date(date.getTime() + ms);
}
const getToday = () => {
    const d = new Date();
    d.setHours(23, 59, 59);
    return d;
};

export function getTimeInMinutes(date: any) {
    if (date && date.getHours) {
        return (date.getHours() * 60) + date.getMinutes();
    }
    return 0;
}

export function formatMinutesAsTime(minutes: number) {
    const h = Math.floor(minutes / 60);
    const m = minutes % 60;
    return `${String(h).padStart(2, "0")}:${String(m).padStart(2, "0")}`;
}

function getTimeInHours(date: any) {
    if (date && date.getHours) {
        return date.getHours() + (date.getMinutes() / 60);
    }
    return 0;
}

function dateOverlap(min1: Date, max1: Date, min2: Date, max2: Date): number {
    return rangeOverlap(min1.getTime(), max1.getTime(), min2.getTime(), max2.getTime());
}

function rangeOverlap(min1: number, max1: number, min2: number, max2: number): number {
    if (min1 > max1 || min2 > max2) {
        throw new Error("Invalid range");
    }
    if (max2 <= min1 || min2 >= max1) /* A + B */ {
        return 0;
    }
    if (min1 <= min2 && max1 >= max2) /* C */ {
        return max2 - min2;
    }
    if (min1 >= min2 && max1 <= max2) /* D */ {
        return max1 - min1;
    }
    if (min1 >= min2 && max1 >= max2 && max2 >= min1) /* E */ {
        return max2 - min1;
    }
    if (min1 <= min2 && max1 >= min2 && max2 >= max1) /* F */ {
        return max1 - min2;
    }
    throw new Error("Invalid case");
}

const isoDateString = (date: Date) => date.toISOString().slice(0, 10);

const todayString = () => {
    return isoDateString(new Date());
};

enum DayOfWeek {
    Sunday = 0,
    Monday = 1,
    Thuesday = 2,
    Wednesday = 3,
    Thursday = 4,
    Friday = 5,
    Saturday = 6
}

let currentLocale = nl;
export function setCurrentLocales(locale: string) {
    const localeUpper = locale.toUpperCase();
    if (localeUpper === "NL") {
        currentLocale = nl;
    } else if (localeUpper === "FR") {
        currentLocale = fr;
    } else {
        currentLocale = enUS;
    }
}

function isValidDate(date: any) {
    return date && Object.prototype.toString.call(date) === "[object Date]" && !isNaN(date);
}

function dateFormat(date: any, formatStr: string) {
    if (!isValidDate(date)) return "";
    return format(date, formatStr, { locale: currentLocale });
}
export { DayOfWeek, getDaysOfMonth, datesEqual, dateAdd, getToday, getTimeInHours, isoDateString, todayString, dateFormat, dateOverlap };
// no empty line
