import { IPortResolver } from "./IPortResolver";

export class BaseResolver {
    private _portResolver: IPortResolver | null = null;

    public setPortResolver = (portResolver: IPortResolver) => { this._portResolver = portResolver; };

    protected resolve<T>(name: string): T {
        if (!this._portResolver) {
            throw new Error("_portResolver is not set!");
        }
        return this._portResolver.resolve<T>(name);
    }
}
