import { ILocalRepository } from "../../domain/storages/ILocalRepository";

export class SessionStoragePlug implements ILocalRepository {
    get<T>(key: string): Promise<T> {
        let item = null;
        const json = sessionStorage.getItem(key);
        if (json) {
            item = JSON.parse(json);
        }
        return Promise.resolve(item);
    }

    getSync<T>(key: string): T {
        let item = null;
        const json = sessionStorage.getItem(key);
        if (json) {
            item = JSON.parse(json);
        }
        return item;
    }

    store(key: string, value: any): Promise<any> {
        sessionStorage.setItem(key, JSON.stringify(value));
        return Promise.resolve(true);
    }

    storeSync(key: string, value: any): void {
        sessionStorage.setItem(key, JSON.stringify(value));
    }

    delete(key: string): Promise<any> {
        sessionStorage.removeItem(key);
        return Promise.resolve(true);
    }
}
