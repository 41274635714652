import { INavigateTo } from "../../domain/navigators/INavigateTo";

export type NavigateFn = (path: string, state?: any) => void;

export class NavigationPlug implements INavigateTo {
    _navigateFn: NavigateFn;
    constructor(navigateFn: NavigateFn) {
        this._navigateFn = navigateFn;
    }

    goBack(): void {
        window.history.back();
    }

    reload(): void {
        window.location.reload();
    }

    go(url: string, params?: any): void {
        setTimeout(() => {
            this._navigateFn(url, params); // params are ignored
        }, 50);
    }
}
