import React, { cloneElement, ReactElement, useEffect, useState } from "react";
import { matchPath } from "react-router";
import { AppRoutes } from "./domain/navigators/AppRoutes";
import { appDomain } from "./domain/wiring/AppDomain";
import { AnimationWrapper } from "./routes/AnimationWrapper";
import { ExtendedRouteProps } from "./routes/ExtendedRouteProps";

enum AuthenticatedState {
    unknown = 0,
    unauthenticated = 1,
    authenticated = 2,
}

interface RenderRouteProps {
    id: string;
    route: ExtendedRouteProps;
    pathName: string;
}

export function RenderRoute(props: RenderRouteProps): JSX.Element {
    const x = props.route;
    const match = matchPath(props.route.path!, props.pathName);

    const [authenticatedState, setAuthenticatedState] = useState(AuthenticatedState.unknown);
    useEffect(() => {
        if (x.private && authenticatedState === AuthenticatedState.unknown) {
            const run = async () => {
                const state = await appDomain.IAuthenticationService.isAuthenticated() ? AuthenticatedState.authenticated : AuthenticatedState.unauthenticated;
                setAuthenticatedState(state);
            };
            run();
        }
    }, [authenticatedState, x.private]);

    if (x.private && authenticatedState !== AuthenticatedState.authenticated) {
        if (authenticatedState === AuthenticatedState.unauthenticated) {
            appDomain.INavigator.navigateTo(AppRoutes.login);
        }
        return <></>;
    }

    if (match && x.element) {
        return <AnimationWrapper key={props.id} routeId={props.id} routePath={props.pathName} className="route-wrapper">{cloneElement(x.element as ReactElement, { params: match.params })}</AnimationWrapper>;
    }
    appDomain.INavigator.navigateTo(AppRoutes.home);
    return <></>;
}
