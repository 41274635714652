import { IRestApi } from "@dna/rest-api-helper";
import { ITranslator } from "@dna/translator";
import { ICanShowAlert } from "../alerts/IRequestAlert";
import { IArticleRepository } from "../articles/IArticleRepository";
import { IAuthenticationRepository } from "../authentication/IAuthenticationRepository";
import { IBackorderRepository } from "../backorders/IBackorderRepository";
import { ICanTransceiveEvents } from "../events/ICanTransceiveEvents";
import { INavigateTo } from "../navigators/INavigateTo";
import { ICanNotify } from "../notifiers/ICanNotify";
import { ISalesTransactionRepository } from "../salesTransactions/ISalesTransactionRepository";
import { ILocalRepository } from "../storages/ILocalRepository";
import { IVersionInfoRepository } from "../versions/IVersionInfoRepository";
import { BaseResolver } from "./BaseResolver";

// tslint:disable:naming-convention
class AppDependencies extends BaseResolver {
    get ICanNotify() { return this.resolve<ICanNotify>("ICanNotify"); }
    get ICanTransceiveEvents() { return this.resolve<ICanTransceiveEvents>("ICanTransceiveEvents"); }
    get ICanTranslate() { return this.resolve<ITranslator>("ITranslator"); }
    get ILocalRepository() { return this.resolve<ILocalRepository>("ILocalRepository"); }
    get ISessionRepository() { return this.resolve<ILocalRepository>("ISessionRepository"); }
    get INavigateTo() { return this.resolve<INavigateTo>("INavigateTo"); }
    get IVersionInfoRepository() { return this.resolve<IVersionInfoRepository>("IVersionInfoRepository"); }
    get ICanShowAlert() { return this.resolve<ICanShowAlert>("ICanShowAlert"); }
    get IRestApi() { return this.resolve<IRestApi>("IRestApi"); }
    get IRestApiWithoutUrl() { return this.resolve<IRestApi>("IRestApiWithoutUrl"); }
    get IAuthenticationRepository() { return this.resolve<IAuthenticationRepository>("IAuthenticationRepository"); }
    get IArticleRepository() { return this.resolve<IArticleRepository>("IArticleRepository"); }
    get IBackorderRepository() { return this.resolve<IBackorderRepository>("IBackorderRepository"); }
    get ISalesTransactionRepository() { return this.resolve<ISalesTransactionRepository>("ISalesTransactionRepository"); }
}

export const appDependencies = new AppDependencies();

export const api = () => appDependencies.IRestApi;
