import { Card } from "antd";
import React from "react";
import { classNames } from "../../../helpers/tsHelper";
import styles from "./FormInputGroup.module.css";
interface IProps {
    title: JSX.Element[] | JSX.Element | string;
    children: JSX.Element[] | JSX.Element | string;
    className?: string;
}
export const FormInputGroup = (props: IProps) => {
    const { children, title } = props;

    return <Card title={title} {...classNames(styles.articleFormCard, props.className ?? "")} bordered={false}>
        {children}
    </Card>;
};
