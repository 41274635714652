import getSymbolFromCurrency from "currency-symbol-map";
import moment from "moment";

const minimumValue = (value: number, min: number) => value < min ? min : value;

const isLast = (arr: Array<any>, i: number) => arr.length - 1 === i;

const nextItemInArray = (arr: Array<any>, i: number) => arr[minimumValue(i + 1, arr.length - 1)];

const unique = (arr: Array<any>) => Array.from(new Set([...arr]));

const getFileExtension = (str: string) => {
    const splitted = str.split(".");
    return splitted[splitted.length - 1].toLowerCase();
};

const toBase64 = (file: Blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

const b64toBlob = (base64: string) => fetch(base64).then(res => res.blob());

const asyncForEach = async (array: Array<any>, callback: Function) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array);
    }
};

function escapeHtml(unsafe: any): string {
    return String(unsafe)
        .replace(/&/g, "&amp;")
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;");
}

const any = (arr: Array<any>) => arr?.length > 0;

const stringComparer = (locales?: any, options?: any) => {
    if (!locales) locales = "en";
    if (!options) options = { numeric: true, sensitivity: "base" };
    const collator = new Intl.Collator(locales, options);
    return (a: string, b: string) => collator.compare(a, b);
};

const stringCompare = stringComparer();

export interface IFilterItem {
    text: string;
    value: any;
}

function getFilter<T>(values: T[keyof T][], fn: (x: T[keyof T]) => IFilterItem) {
    return Array.from(new Set(values)).map(fn);
}

function escapeRegExp(s: string) {
    return String(s).replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
}

function getPropertyValue<T, K extends keyof T>(o: T, propertyName: K): T[K] {
    return o[propertyName];// o[propertyName] is of type T[K]
};

const createQuery = (data: any) => {
    const c = encodeURIComponent;
    const cv = (v: any) => v && v.toISOString ? v.toISOString() : v;
    return "?" + Object.keys(data).map(k => `${c(k)}=${c(cv(data[k]))}`).join("&");
};

export const stringIsNullOrEmpty = (str: any) => {
    if (typeof str !== "string") return true;
    if (str.trim().length < 1) return true;
    return false;
};

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key: any, value: any) => {
        if (typeof value === "object" && value !== null) {
            if (seen.has(value)) {
                return;
            }
            seen.add(value);
        }
        return value;
    };
};

const createGuid = (): string => {
    let d = new Date().getTime();
    const uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        const r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        // eslint-disable-next-line no-mixed-operators
        return (c === "x" ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
};

const toJson = (x: any) => JSON.stringify(x, getCircularReplacer());

const parseJson = (x: any) => {
    if (typeof x !== "string") return undefined;
    try {
        return JSON.parse(x);
    } catch {
        return undefined;
    }
};

export const getNumber = (x: any, def: number): number => {
    if (typeof x === "number" && isFinite(x)) return x;
    if (!x) return def;
    if (typeof x === "string") return getNumber(Number(x), def);
    return def;
};

const reIdentifier = /^[0-9a-z][0-9a-z_-]*$/i;
export const isIdentifier = (value: any) => {
    if (typeof value !== "string" && typeof value !== "number") return false;
    return reIdentifier.test(String(value).trim());
};

export const getLocalStorage = <T>(key: string): T => parseJson(localStorage.getItem(key)) as T;
export const setLocalStorage = (key: string, state: any): any => localStorage.setItem(key, toJson(state));
export const removeLocalStorage = (key: string): any => localStorage.removeItem(key);

const getLocationStateKey = (path: string) => ("state-" + String(path)).toLowerCase();
const getLocationState = <T>(path: string): T => parseJson(sessionStorage.getItem(getLocationStateKey(path))) as T;
const setLocationState = (path: string, state: any): any => sessionStorage.setItem(getLocationStateKey(path), toJson(state));

const formatDate = (value: Date) => value ? moment(value).format("DD-MM-yyyy") : "";

export function isFilled(value?: string, minLength: number = 3) {
    return typeof value === "string" && value.length >= minLength;
}

export const roundNumberBy = (number: number, roundBy: number) => Math.round(number * 1.0 / roundBy) * roundBy;

export const roundNumberUpBy = (number: number, roundBy: number) => Math.ceil(number * 1.0 / roundBy) * roundBy;

interface IGroup<U> {
    group: string;
    data: U;
}
export function groupBy<T, U>(array: T[], fn: (item: T) => IGroup<U>) {
    const groups: { [key: string]: U[] } = {};
    array.forEach((item) => {
        const result = fn(item);
        const group = JSON.stringify(result.group);
        groups[group] = groups[group] || [];
        groups[group].push(result.data);
    });
    return Object.keys(groups).map((group) => groups[group]);
};

export function delay(timeout: number): Promise<void> {
    return new Promise((resolve, reject) => {
        setTimeout(resolve, timeout);
    });
}

export function moneyFormat(amount: number, currencyISOcode: string) {
    const currency = getSymbolFromCurrency(currencyISOcode) ?? " ";
    return `${currency} ${amount.toFixed(2)}`;
}

type stringOrUndefined = string| undefined;
export function classNames(...classes: stringOrUndefined[]) {
    return { className: classes.filter(x => !!x).join(" ") };
}

const saveBlob = (blob: any, fileName: string) => {
    var elem = document.createElement("a");
    document.body.appendChild(elem);
    var url = window.URL.createObjectURL(blob);
    elem.href = url;
    elem.download = fileName;
    elem.click();
    window.URL.revokeObjectURL(url);
};

export {
    minimumValue, isLast, nextItemInArray, unique, getFileExtension, toBase64, b64toBlob,
    asyncForEach, any, escapeHtml, stringComparer, stringCompare, getFilter, escapeRegExp,
    getPropertyValue, createQuery, toJson, parseJson, createGuid, formatDate,
    getLocationState, setLocationState, saveBlob
};
// --
