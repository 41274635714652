import { appDependencies } from "../wiring/AppDependencies";
import { IEventEmitter } from "./IEventEmitter";
import { IEventHandler, IEventSubscription } from "./IEventHandler";

export class EventService implements IEventHandler, IEventEmitter {
    emit(eventName: string, data?: any): void {
        appDependencies.ICanTransceiveEvents.publish(eventName, data);
    }

    subscribe<T>(eventName: string, fn: (data: T) => void): IEventSubscription {
        return appDependencies.ICanTransceiveEvents.subscribe(eventName, fn);
    }
}
