import React, { } from "react";
import { Collapse } from "antd";
import Table, { ColumnsType } from "antd/lib/table";
import { IBackorder } from "../../../domain/backorders/IBackorder";
import styles from "../../backorder/BackorderView.module.css";
import styles2 from "../../backorder/block/BackorderBlock.module.css";
import { translate } from "../../../domain/wiring/AppDomain";
import { ArrowDownOutlined } from "@ant-design/icons";
import moment from "moment";

interface IProps {
    order: IBackorder;
    key: string;
}

interface IBackorderLine {
    key: string;
    id: string,
    text: string,
    price: string,
    total: string,
    unit: string,
    deliveryDate: Date,
    count: number
}

const { Panel } = Collapse;

function getBackorderLineData(order: IBackorder): IBackorderLine[] {
    const a: IBackorderLine[] = [];
    order.lines.map(value => {
        return a.push({ key: value.articleNumber, id: value.articleNumber, text: value.text, price: value.price.toFixed(2), total: value.total.toFixed(2), unit: value.unit, deliveryDate: value.deliveryDate, count: value.count });
    });

    return a;
};

const orderCountStyle = (count: number) => {
    return <text className={styles.panelHeaderCircle}>{count}</text>;
};

const getTime = (time: Date) => {
    if (time === undefined || time === null) { return ""; }
    return moment(time).format("DD-MM-YYYY");
};

export const BackorderBlock = (props: IProps) => {
    const { order } = props;
    const columns = getColumns();
    return (
        <Panel header={
            <div className={styles2.flexItems}>
                <div className={styles2.orderNumber}>
                    <div >{translate("orderNumber") + " #" + order.number + " "}</div>
                </div>
                <div className={styles2.datetime}>
                    <span>{" " + translate("created")} : {(getTime(order.createdTime) ? getTime(order.createdTime) : translate("unknown"))}</span>
                </div>
                <div className={styles2.datetime}>
                    <span>{translate("deliveryDate")} : {(getTime(order.deliveryDate) ? getTime(order.deliveryDate) : translate("unknown"))}</span>
                </div>
                <div className={styles2.paddingCount2}>
                    <span>{translate("numberOfRecords") + " "}{ orderCountStyle(order.count)}</span>
                </div>
                <div className={styles2.arrow}>
                    <ArrowDownOutlined />
                </div>

            </div>
        } key={props.key} showArrow={false}>
            <div className={styles.panelContent}>
                <div className={styles.flexColumn}>
                    <div>
                        <Table pagination={false} showHeader={true} columns={columns} dataSource={getBackorderLineData(order)} />
                    </div>
                </div>
                <div className={styles.totalLine}>
                    {order.amount.toFixed(2)}
                </div>
            </div>
        </Panel>
    );
};

export function getColumns() {
    const columns: ColumnsType<IBackorderLine> = [
        {
            title: translate("item"),
            dataIndex: "id",
            key: "id-{id}",
            width: "15%"
        },
        {
            title: translate("description"),
            dataIndex: "text",
            key: "text-{text}",
            width: "15%"
        },
        {
            title: translate("price"),
            dataIndex: "price",
            key: "price-{id}",
            align: "right",
            width: "10%"
        },
        {
            title: translate("Qty"),
            dataIndex: "count",
            key: "count-{id}",
            align: "center",
            width: "5%"
        },
        {
            title: translate("unit"),
            dataIndex: "unit",
            key: "unit-{id}",
            align: "right",
            width: "10%"
        },
        {
            title: translate("total"),
            dataIndex: "total",
            key: "total-{id}",
            align: "right",
            width: "10%"
        }
    ];
    return columns;
}
