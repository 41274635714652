import React from "react";
import { message, Spin } from "antd";
import { translate } from "../../../domain/wiring/AppDomain";

import styles from "./LoadingIndicator.module.css";

export function LoadingIndicator(props: { isLoading?: boolean, label?: string}): JSX.Element {
    if (!props.isLoading) return <></>;
    return <div className={ styles.loadingIndicator}>
        <Spin tip={translate(props.label ?? "loadingMsg")}></Spin>
    </div>;
}
