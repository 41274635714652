import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { ICanNotify } from "../../domain/notifiers/ICanNotify";
import { IMessageToDisplay } from "../../domain/notifiers/IMessageToDisplay";

export class NotifierPlug implements ICanNotify {
    async notify(messageToDisplay: IMessageToDisplay): Promise<any> {
        return await withReactContent(Swal).fire(messageToDisplay);
    }
}
