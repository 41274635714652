import { PlusOutlined } from "@ant-design/icons";
import React from "react";
import { PageWrapper } from "../../components/core/PageWrapper/PageWrapper";
import { navigateToWithParams, AppRoutes } from "../../domain/navigators/AppRoutes";
import { translate } from "../../domain/wiring/AppDomain";
import { HeaderActionBar, IActionButton } from "../home/headerbar/HeaderActionBar";
import styles from "../home/HomePageView.module.css";
import { BackorderList } from "./list/BackorderList";

interface IProps { }

export function BackorderView(props: IProps) {
    return <PageWrapper className={styles.homePageView} pageHeaderTitle={translate("fp-home.title")}>
        <div>
            <BackorderList />
        </div>
    </PageWrapper>;
};
