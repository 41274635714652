import { MenuOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { EnumSlideDirection, SlideAnimationWrapper } from "../../../routes/SlideAnimationWrapper";
import { OnlyWhen } from "../DeleteAction";
import styles from "./MenuToggleButton.module.css";
import { NavMenu } from "./NavMenu";

interface IProps { }

export function MenuToggleButton(props: IProps) {
    const [isOpen, setOpen] = useState(false);

    const rect = {
        width: 258,
        height: window.innerHeight
    };

    return <>
        <div className={styles.menuToggleButton} onClick={() => setOpen(!isOpen)}>
            <MenuOutlined />
        </div>
        {OnlyWhen(isOpen, () => <div className={styles.menuWrapper}>
            <div className={styles.menuCloseOverlay} onClick={(e) => { setOpen(false); return true; }}>&nbsp;</div>
            <SlideAnimationWrapper animate={isOpen ? "visible" : "initial"} rect={rect} direction={EnumSlideDirection.Left} className={styles.menuContent}>
                <NavMenu closeMenu={() => setOpen(false)} />
            </SlideAnimationWrapper>
        </div>)}
    </>;
}
