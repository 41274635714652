import { CachedPromise } from "../../helpers/CachedPromise";
import { appDependencies } from "../wiring/AppDependencies";
import { ISalesTransactionLine } from "./ISalesTransaction";
import { ISalesTransactionService } from "./ISalesTransactionService";

export class SalesTransactionService implements ISalesTransactionService {
    clearCache(): void {
        this.backorder.setExpired();
    }

    getAll(): Promise<ISalesTransactionLine[]> {
        return this.backorder.load();
    };

    async getAllNoCache(firstDate: Date, lastDate: Date): Promise<ISalesTransactionLine[]> {
        return await appDependencies.ISalesTransactionRepository.getAll(firstDate, lastDate);
    }

    private readonly backorder = new CachedPromise<ISalesTransactionLine[]>(() => appDependencies.ISalesTransactionRepository.getAll(), 1); // short caching
}
