import { ColumnsType, ColumnType } from "antd/lib/table";
import React from "react";
import { SearchableList } from "../../../domain/core/SearchableList";
import { EventNames } from "../../../domain/events/EventNames";
import { IEventSubscription } from "../../../domain/events/IEventHandler";
import { UserRole } from "../../../domain/user/UserRole";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";
import { getFilter, getPropertyValue, stringCompare } from "../../../helpers/tsHelper";

export class BaseComponent<P = {}, S = {}, SS = any> extends React.Component<P, S, SS> {
    private _unmounted = false;
    private _subscriptions: IEventSubscription[];
    public searchableList: SearchableList<any> | null = null;
    public isSubscribedToSearch: boolean = false;

    constructor(props: P) {
        super(props);
        this._subscriptions = [];
    }

    setStateWhenMounted<K extends keyof S>(state: ((prevState: Readonly<S>, props: Readonly<P>) => (Pick<S, K> | S | null)) | (Pick<S, K> | S | null), callback?: () => void): void {
        if (this.mounted()) {
            this.setState(state, callback);
        }
    }

    mounted() {
        return !this._unmounted;
    }

    componentWillUnmount() {
        this._unmounted = true;
        this._subscriptions.forEach(s => s.unsubscribe());
    }

    initSearch<T>(data: T[], searchTextFn: (x: T) => string, onChange: (filtered: T[], search: string) => void) {
        this.searchableList = new SearchableList(data, searchTextFn);
        const searchText = appDomain.ISearchService.getSearchText();
        onChange(this.searchableList!.search(searchText), searchText);
        if (!this.isSubscribedToSearch) {
            this.isSubscribedToSearch = true;
            this.subscribe(EventNames.search, searchText => {
                onChange(this.searchableList!.search(searchText), searchText);
            });
        }
    }

    addEventListener(element: EventTarget, event: string, handler: EventListenerOrEventListenerObject) {
        element.addEventListener(event, handler);
        this.addSubscription({ unsubscribe: () => element.removeEventListener(event, handler) });
    }

    addSubscription(subscription: IEventSubscription) {
        this._subscriptions.push(subscription);
    }

    subscribe(event: string, fn: (data: any) => void) {
        const subscription = appDomain.IEventHandler.subscribe(event, fn);
        this.addSubscription(subscription);
        return subscription;
    }

    subscribeInterval(fn: Function, ms: number): void {
        const interval = setInterval(fn, ms);
        this.addSubscription({ unsubscribe: () => clearInterval(interval) });
    }

    translate(key: string, params?: any): string {
        return translate(key, params);
    }

    navigateTo(url: string, params: any = {}): void {
        appDomain.INavigator.navigateTo(url, params);
    }

    getRole(): UserRole {
        // return UserRole.production;
        return appDomain.IAuthenticationService.getUserRole();
    }

    get isAdmin() {
        return this.getRole() === UserRole.administrator;
    }

    addFilterAndSort<T extends object>(data: T[], columns: ColumnsType<T>, overRides: IFilterOverride<T>[] = []): ColumnsType<T> {
        for (let index = 0; index < columns.length; index++) {
            const element = columns[index] as ColumnType<T>;

            if (element.dataIndex) {
                const propName = element.dataIndex.toString() as keyof T;

                const overRide = overRides.find(x => x.dataIndex === propName);

                const values = data.map(x => getPropertyValue(x, propName));

                const allFilterValuesGetter = overRide
                    ? (x: T[keyof T]) => ({ text: overRide.valueGetter(x), value: overRide.valueGetter(x) })
                    : (x: T[keyof T]) => ({ text: `${x}`, value: x });

                const valueGetter = overRide
                    ? (x: T, p: keyof T): string => overRide.valueGetter(getPropertyValue(x, p))
                    : (x: T, p: keyof T): string => `${getPropertyValue(x, p)}`;

                const valuesWithoutEmpty = values.filter(x => x !== undefined);

                element.filters = getFilter(valuesWithoutEmpty, allFilterValuesGetter);
                element.onFilter = (value, x) => valueGetter(x, propName).includes(value.toString());

                element.sorter = (a, b) => stringCompare(valueGetter(a, propName), valueGetter(b, propName));
            }
        }

        return columns;
    }
}

export interface IFilterOverride<T> {
    dataIndex: keyof T;
    valueGetter: (value: any) => string;
}
