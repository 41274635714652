import { Collapse } from "antd";
import React, { useEffect, useState } from "react";
import { IBackorder } from "../../../domain/backorders/IBackorder";
import { appDomain } from "../../../domain/wiring/AppDomain";
import { BackorderBlock } from "../block/BackorderBlock";
import styles from "../../backorder/BackorderView.module.css";
import { LoadingIndicator } from "../../../components/basic/LoadingIndicator/LoadingIndicator";

export function BackorderList() {
    const [orders, setOrders] = useState<IBackorder[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const [searchText, setSearchText] = useState<string>("");

    const collapseStyle = {
        backgroundColor: "#184c8c"
    };

    useEffect(() => {
        const init = async () => {
            const data = await appDomain.IBackorderService.getAll();
            setOrders(data.reverse());
            setLoaded(true);
        };
        init();
    }, []);

    if (!loaded) return <LoadingIndicator isLoading={true} />;

    return <Collapse className={styles.collapseHeader}>
        {
            orders.map(x => BackorderBlock({
                order: x,
                key: `order-${x.number}`
            }))
        }
    </Collapse>;
}
