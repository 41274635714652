import { ILocalRepository } from "../../domain/storages/ILocalRepository";

export class LocalStoragePlug implements ILocalRepository {
    private parseJson(str: string | null) {
        if (!str) return null;
        try {
            return JSON.parse(str);
        } catch {
            return null;
        }
    }

    get<T>(key: string): Promise<T> {
        const json = localStorage.getItem(key);
        return Promise.resolve(this.parseJson(json));
    }

    getSync<T>(key: string): T {
        const json = localStorage.getItem(key);
        return this.parseJson(json);
    }

    store(key: string, value: any): Promise<boolean> {
        localStorage.setItem(key, JSON.stringify(value));
        return Promise.resolve(true);
    }

    storeSync(key: string, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    delete(key: string): Promise<any> {
        localStorage.removeItem(key);
        return Promise.resolve(true);
    }
}
