import { IBackorder } from "../../domain/backorders/IBackorder";
import { IBackorderRepository } from "../../domain/backorders/IBackorderRepository";
import { appDependencies } from "../../domain/wiring/AppDependencies";

export class BackorderRepository implements IBackorderRepository {
    async getAll(): Promise<IBackorder[]> {
        const articles = await appDependencies.IRestApi
            .request("/api/Backorder")
            .getData<IBackorder[]>();
        return articles;
    }
}
