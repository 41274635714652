import React, { useEffect, useState } from "react";
import { EventNames } from "../../../domain/events/EventNames";
import { appSubscribe, translate } from "../../../domain/wiring/AppDomain";
import { PageHeaderLogo } from "../PageHeaderLogo";
import { PageWrapperProps } from "./PageWrapper";
import styles from "./PageWrapperHeader.module.css";

export function PageWrapperHeader(props: PageWrapperProps): JSX.Element {
    const { pageHeaderTitle, headerActionBar } = props;

    useEffect(() => {
        showHeader();
    }, []);

    function showHeader() {
        var translatedHeader = translate("fp-home.title");
        if (translatedHeader === "" ||
            translatedHeader === undefined) {
            return ("Vender Portal");
        }
        return translate("fp-home.title");
    }

    return <div className={styles.headerSpace}>
        <div className={styles.headerContent}>
            <div className={styles.headerLogo}><PageHeaderLogo /></div>
            <h2 className={styles.pageTitle}>{showHeader()}</h2>
        </div>
        {headerActionBar}
    </div>;
}
