import { IRestApiExceptionHandler, IRestApiRequest, IRestApiResponse } from "@dna/rest-api-helper";
import { escapeHtml } from "../../helpers/tsHelper";
import { ValidationError } from "../validation/ValidationError";
import { appDependencies } from "../wiring/AppDependencies";
import { appDomain, translate } from "../wiring/AppDomain";
import { IHandleExceptions } from "./IHandleExceptions";

export class ExceptionService implements IHandleExceptions, IRestApiExceptionHandler {
    handleException(exception: any) {
        if (exception?.reason instanceof ValidationError) {
            const results: ValidationError = exception.reason;

            console.log("Validation errors:", (exception as ValidationError).validationResults);

            appDependencies.ICanNotify.notify({
                title: translate("error"),
                html: results.validationResults.map(x => `${translate(x.fieldName)}: ${translate(x.message)}`).join("<br />"),
                icon: "warning"
            });

            return;
        }

        if (exception instanceof Error && (exception as any).xhr) {
            const xhr = (exception as any).xhr.rawRequest as XMLHttpRequest;

            if (xhr.status === 400) {
                try {
                    const result = JSON.parse(xhr.response);
                    if (result.error === "invalid_grant") {
                        appDependencies.ICanNotify.notify({
                            title: translate("error.failed-logon.title"),
                            html: translate("error.failed-logon.text"),
                            icon: "warning"
                        });
                        return;
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            if (xhr.status === 401) {
                throw new Error("Not implemented!, please implement with new OAuth method");
            }

            if (xhr.status === 422) {
                const validationResults = JSON.parse(xhr.response).results.map((y: any) => translate(y.message, y.parameters)).join(", ");
                appDependencies.ICanShowAlert.error(translate("general.validation-error"), validationResults);
                return;
            }

            let title = `HTTP error, status: ${xhr.status}`;
            if (xhr.status === 0) {
                title = translate("error.no-network-connection");
            }

            appDependencies.ICanNotify.notify({ title: title, html: xhr.responseURL, icon: "error" });
            return;
        }

        if (exception instanceof Event || exception instanceof Error) {
            console.log("Exception event:", exception);
            return;
        }

        const error = new Error("Domain error not handled");
        console.warn(error, exception);
        throw error;
    }

    isResponseAnException(response: IRestApiResponse): boolean {
        return response.status > 400;
    }

    async onHttpException(options: IRestApiRequest, response: IRestApiResponse, error: Error) {
        const status = response?.status;
        if (!status) {
            appDomain.IRequestNotify.notify({
                title: "Network connection error",
                html: `API not available<br>${options.path}`,
                icon: "error"
            });
            return;
        }

        // not auth
        if (status === 401) {
            return;
        }

        if (status === 422) {
            const content = response.data || response.body;
            const validationResults = content.results.map((y: any) => translate(y.message, y.parameters)).join(", ");
            appDomain.IRequestNotify.notify({
                title: translate("general.validation-error"),
                html: validationResults,
                icon: "error"
            });
            return;
        }

        if (response) {
            const content = response.data || response.body;
            appDomain.IRequestNotify.notify({
                title: `HttpError ${response.status}`,
                html: `<pre class="code">${response.url}\n${escapeHtml(JSON.stringify(content, null, 4))}</pre>`,
                icon: "error"
            });
            return;
        }

        appDomain.IRequestNotify.notify({
            title: `HttpError ${status}`,
            html: `<pre class="code">${options.path}\n${escapeHtml(JSON.stringify(options, null, 4))}</pre>`,
            icon: "error"
        });
    }
}
