import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { PageWrapper } from "../../components/core/PageWrapper/PageWrapper";
import { navigateToWithParams, AppRoutes } from "../../domain/navigators/AppRoutes";
import { appDomain, translate } from "../../domain/wiring/AppDomain";
import { HeaderActionBar, IActionButton, IToggleSwitch } from "./headerbar/HeaderActionBar";
import styles from "./HomePageView.module.css";
import { ArticleList } from "./list/ArticleList";
import { LocalStorageKeys } from "../../domain/storages/LocalStorageKeys";

interface IProps { }

export const HomePageView = (props: IProps): JSX.Element => {
    const [useList, setUseList] = useState(() => {
        const useListState = JSON.parse(localStorage.getItem("useListState") || "false");
        return useListState;
    });

    const [filterInactive, setFilterInactive] = useState(() => {
        const filterInactiveState = appDomain.IStateService.getSync<boolean>(LocalStorageKeys.filterInactive);
        return filterInactiveState;
    });

    React.useEffect(() => {
        localStorage.setItem("useListState", JSON.stringify(useList));
    }, [useList]);

    React.useEffect(() => {
        appDomain.IStateService.set(LocalStorageKeys.filterInactive, filterInactive);
    });

    const buttons: IActionButton[] = [
        {
            position: "right",
            content: <>{ translate("add") }</>,
            icon: <PlusOutlined />,
            onClick: () => navigateToWithParams(AppRoutes.articleView, "create")
        }
    ];

    const toggleSwitch: IToggleSwitch = {
        content: <>{ translate("fp-list.view") }</>,
        onChange: () => {
            setUseList(!useList);
        },
        state: useList
    };

    const toggleInactiveFilterSwitch: IToggleSwitch = {
        content: <>{translate("fp-article.filter.inactive")}</>,
        onChange: () => {
            setFilterInactive(!filterInactive);
        },
        state: filterInactive
    };

    return <PageWrapper className={styles.homePageView} pageHeaderTitle={translate("fp-home.title")} headerActionBar={<HeaderActionBar buttons={buttons} toggleswitch={toggleSwitch} toggleInactiveFilterSwitch={toggleInactiveFilterSwitch} />}>
        <div className={styles.content}>
            <ArticleList useList={useList} filterInactive={filterInactive}/>
        </div>
    </PageWrapper>;
};
