import { LeftCircleFilled } from "@ant-design/icons";
import { Button, DatePicker, TableColumnsType, Table, TableProps, Input } from "antd";


import moment from "moment";
import React, { useEffect, useState } from "react";
import { ISalesTransactionLine } from "../../../domain/salesTransactions/ISalesTransaction";
import { appDependencies } from "../../../domain/wiring/AppDependencies";
import { translate } from "../../../domain/wiring/AppDomain";
import styles from "../../salesTransaction/block/SalesTransactionBlock.module.css";



interface IProps {
    salesTransactionLine: ISalesTransactionLine[];
    key: string;
}
interface ISalesTransLine {
    key: React.Key;
    number: string,
    invoiceNumber: string,
    debtor: string,
    text: string,
    count: number,
    unit: string,
    price: string,
    totalPrice: string,
    date: string
}

function getExpendedRowData(props: IProps): ISalesTransLine[] {
    const { salesTransactionLine } = props;
    const expendedRowData: ISalesTransLine[] = [];

    salesTransactionLine.sort(function (left, right) {
        return moment.utc(left.date).diff(moment.utc(right.date));
    });

    for (let i = 0; i < salesTransactionLine.length; ++i) {
        expendedRowData.push({
            key: i.toString(),
            number: salesTransactionLine[i].number,
            date: (moment(salesTransactionLine[i].date).format("DD-MM-YYYY")),
            invoiceNumber: salesTransactionLine[i].invoiceNumber,
            debtor: salesTransactionLine[i].debtor,
            text: salesTransactionLine[i].text,
            count: salesTransactionLine[i].count,
            unit: salesTransactionLine[i].unit,
            price: salesTransactionLine[i].price?.toFixed(2),
            totalPrice: salesTransactionLine[i].totalPrice?.toFixed(2)
        });
    };

    return expendedRowData;
};
export function SalesTransactionBlock(props: IProps) {
    const expendedRowColumns: TableColumnsType<ISalesTransLine> = [
        {
            title: translate("date"),
            dataIndex: "date",
            key: "date",
            align: "left",
            width: "5%",
            sorter: (a, b) => moment(a.date, "DD-MM-YYYY").unix() - moment(b.date, "DD-MM-YYYY").unix()
        },
        {
            title: translate("debtor"),
            dataIndex: "debtor",
            key: "debtor",
            align: "left",
            width: "6%",
            sorter: (a, b) => a.debtor.localeCompare(b.debtor),
            ellipsis: true
        },
        { title: translate("itemNumber"), dataIndex: "number", key: "number", align: "left", width: "5%", sorter: (a, b) => a.number?.localeCompare(b.number ? "" : "") },
        { title: translate("description"), dataIndex: "text", key: "text", align: "left", width: "15%", sorter: (a, b) => a.text.localeCompare(b.text) },
        {
            title: translate("qty"),
            dataIndex: "count",
            key: "count",
            align: "center",
            width: "5%",
            sorter: (a, b) => a.count - b.count
        },
        { title: translate("unit"), dataIndex: "unit", key: "unit", align: "right", width: "5%", sorter: (a, b) => a.unit?.localeCompare(b.unit ? "" : "") },
        { title: translate("price"), dataIndex: "price", key: "price", align: "right", width: "5%", sorter: (a, b) => Number(a.price) - Number(b.price) },
        { title: translate("total"), dataIndex: "totalPrice", key: "totalPrice", align: "right", width: "5%", sorter: (a, b) => Number(a.price) - Number(b.price) }
    ];

    return (
        <>
            <div>
                <Table className={styles.nestedRow} columns={expendedRowColumns} dataSource={getExpendedRowData(props)} size="small" bordered={true}/>
            </div>
        </>
    );
};
