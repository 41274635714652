import { ILanguageConfiguration, ITranslatorProvider } from "@dna/translator";
import { GetLocalState, SafeLocalState } from "../../helpers/LocalStorageHelper";
import { EventNames } from "../events/EventNames";
import { AppRoutes } from "../navigators/AppRoutes";
import { LocalStorageKeys } from "../storages/LocalStorageKeys";
import { appDependencies } from "../wiring/AppDependencies";
import { appDomain, navigateTo } from "../wiring/AppDomain";
import { IRequestTranslation } from "./IRequestTranslation";
import { LanguageCulture } from "./LanguageCulture";

export class TranslationService implements IRequestTranslation, ITranslatorProvider {
    constructor() {
        var language = GetLocalState("currentLanguage", "");
        if (language === null) { SafeLocalState("currentLanguage", "nl"); }

        appDependencies.IRestApi
            .request("api/translation/available")
            .getData<LanguageCulture[]>()
            .then((availableLanguages: LanguageCulture[]) => {
                this.config = availableLanguages.map(x => this.convertToConfigItem(x));
                this.changeLanguage(appDependencies.ICanTranslate.getCurrentLanguage());
            });
    }

    public config: ILanguageConfiguration[] = [this.convertToConfigItem({ cultureCode: "nl", name: "Dutch" })];

    public persistLanguageKey = {
        setCurrentLanguageKey: (key: string) => appDependencies.ILocalRepository.store(LocalStorageKeys.currentLanguage, key),
        getCurrentLanguageKey: () => appDependencies.ILocalRepository.getSync<string>(LocalStorageKeys.currentLanguage)
    };

    private convertToConfigItem(value: LanguageCulture): ILanguageConfiguration {
        return {
            languageFileLoader: () => appDependencies.IRestApi.request(`api/translation/${value.cultureCode}`).getData<Map<string, string>>(),
            languageKey: value.cultureCode,
            languageLabel: value.name,
            default: value.cultureCode === "nl"
        };
    }

    getCurrentLanguage(): Promise<string> {
        throw new Error("Method not implemented.");
    }

    translate(translationKey: string, obj?: {}): string {
        return appDependencies.ICanTranslate.translate(translationKey, obj);
    }

    fromCacheOrDefault(): Promise<void> {
        return appDependencies.ICanTranslate.loadCurrentLanguageFile();
    }

    public changeLanguage = async (language: string): Promise<boolean> => {
        appDependencies.ICanTranslate.setCurrentLanguage(language);
        await appDependencies.ICanTranslate.loadCurrentLanguageFile();
        appDomain.IEventEmitter.emit(EventNames.languageChanged);
        navigateTo(AppRoutes.home);
        return true;
    };

    public languageChanged(languageKey: string): void {
        appDomain.IEventEmitter.emit(EventNames.languageChanged);
    };

    public getCurrentLanguageSync = (): string => appDependencies.ICanTranslate.getCurrentLanguage();

    public async getAvailableLanguages(): Promise<LanguageCulture[]> {
        const languages = appDependencies.ICanTranslate.getAvailableLanguages();
        return languages.map(x => ({ cultureCode: x.languageKey, name: x.languageLabel }));
    }
}
