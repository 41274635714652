import { CachedPromise } from "../../helpers/CachedPromise";
import { appDependencies } from "../wiring/AppDependencies";
import { IBackorder } from "./IBackorder";
import { IBackorderService } from "./IBackorderService";

export class BackorderService implements IBackorderService {
    getAll(): Promise<IBackorder[]> {
        return this.backorder.load();
    }

    private readonly backorder = new CachedPromise<IBackorder[]>(() => appDependencies.IBackorderRepository.getAll(), 1); // short caching

    clearCache() { this.backorder.setExpired(); }
}
