import { EventNames } from "../events/EventNames";
import { appDomain } from "../wiring/AppDomain";
import { ValidationError } from "./ValidationError";
import { ValidationResult } from "./ValidationResult";

export class ValidationResults {
    validationResults: Array<ValidationResult>;

    constructor() {
        appDomain.IEventEmitter.emit(EventNames.validationError, null);
        this.validationResults = [];
    }

    addResult(fieldName: string, message: string) {
        this.validationResults.push(new ValidationResult(fieldName, message));
    }

    addTranslationResult(message: any, fieldName? : any) {
        this.validationResults.push(new ValidationResult(fieldName, appDomain.IRequestTranslation.translate(message)));
    }

    addResultIfTrue(condition: boolean, fieldName: string, message: string) {
        if (condition) {
            this.validationResults.push(new ValidationResult(fieldName, message));
        }
        return this;
    }

    get(): ValidationResult[] {
        return Array.from(this.validationResults);
    }

    getByField(field: string): ValidationResult[] {
        return Array.from(this.validationResults).filter(x => x.fieldName === field);
    }

    isValid() {
        return this.validationResults.length === 0;
    }

    throwIfNotValid() {
        if (this.validationResults.length > 0) {
            throw new ValidationError(this.validationResults);
        }
    }
}
