import { dateAdd } from "./DateHelpers";

export class CachedPromise<T> {
    private _dataPromise: PromiseLike<T> | undefined = undefined;
    private _dataDate: Date | undefined;
    constructor(private _dataFn: () => PromiseLike<T>, private _validMinutes: number = 20) { }
    async load(reload: boolean = false) {
        if (!this._dataPromise || reload || this.isExpired()) {
            this._dataDate = new Date();
            return this.reload();
        }
        return await this._dataPromise;
    }

    isExpired(): boolean {
        const expiryDate = dateAdd(new Date(), -1 * this._validMinutes * 60 * 1000);
        const isExpired = !this._dataDate || this._dataDate < expiryDate;
        return isExpired;
    }

    setExpired() {
        this._dataDate = undefined;
    }

    async reload() {
        this._dataPromise = this._dataFn();
        return await this._dataPromise;
    }
}
