import { navigateTo } from "../wiring/AppDomain";

export enum AppRoutes {
    home = "/home",
    logout = "/logout",
    unauthorized = "/unauthorized",
    login = "/login",
    backorder = "/backorder",
    articleView = "/article/:articleId",
    salesTransaction = "/salestransaction"
}

export function replaceAppRoutesParams(route: AppRoutes, ...params: string[]) {
    var path = String(route);
    let i = 0;
    const getParam = () => {
        const param = params[i];
        i += 1;
        return param;
    };
    return path.replaceAll(/(:[^/]+)/g, getParam);
}

export function navigateToWithParams(route: AppRoutes, ...params: string[]) {
    navigateTo(replaceAppRoutesParams(route, ...params));
}
