import { appDomain } from "./domain/wiring/AppDomain";

export class GlobalErrorHandler {
    constructor() {
        const self = this;
        window.addEventListener("error", function (e: any) {
            if (e.stopPropagation) {
                e.stopPropagation();
                e.preventDefault();
            }
            self.handleError(e || e.detail || e.detail.reason);
            return false;
        });
        window.addEventListener("unhandledrejection", function (e: any) {
            if (e.stopPropagation) {
                e.stopPropagation();
                e.preventDefault();
            }
            self.handleError(e || e.detail || e.detail.reason);
            return false;
        });
    }

    handleError(error: any) {
        if (error.rejection) {
            error = error.rejection;
        }
        appDomain.IHandleExceptions.handleException(error);
    }
}

// eslint-disable-next-line no-new
new GlobalErrorHandler();
