import React from "react";

interface IProps {
    className?: string;
}

export function AppLogo(props: IProps) {
    const { className } = props;
    return <img srcSet="assets/images/app-logo.png 1x, assets/images/app-logo-2x.png 2x" src="assets/images/app-logo.png" alt="App Logo" className={className} />;
}
