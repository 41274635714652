import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { appDependencies } from "./domain/wiring/AppDependencies";
import "./GlobalErrorHandler";
import "./index.less";
import { Wiring } from "./wiring/Wiring";

export const wiring = new Wiring();

async function StartApp() {
    await appDependencies.ICanTranslate.loadCurrentLanguageFile();
    ReactDOM.render(<App />, document.getElementById("root"));
}

StartApp();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
