import React, { useEffect, useState } from "react";
import { List, Modal, Popover } from "antd";
import { LanguageCulture } from "../../../domain/translations/LanguageCulture";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";

import styles from "./LanguageSelector.module.css";

export function LanguageSelectionPopup(props: { visible: boolean, hide: () => void }) {
    const [langu, setLanguagesNames] = useState<Array<String>>([]);
    const [languages, setLanguageCulture] = useState<Array<LanguageCulture>>([]);
    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const init = async () => {
            setInitLoading(false);

            var languages = await appDomain.IRequestTranslation.getAvailableLanguages();
            setLanguageCulture(languages);

            setLanguagesNames(languages.map(a => a.name));
            setLoading(false);
        };
        init();
    }, []);

    const TranslateTo = async (item: any) => {
        var cultureCode = languages.find(x => x.name === item)?.cultureCode;
        if (cultureCode) {
            await appDomain.IRequestTranslation.changeLanguage(cultureCode);
        }
        props.hide();
        window.location.reload();
    };

    return <Modal title={translate("setupLanguage")} visible={props.visible} okButtonProps={{ style: { display: "none" } }} cancelText={translate("cancel")} onCancel={() => { props.hide(); }}>
        <List
            className={styles.list}
            size="default"
            bordered
            loading={initLoading}
            loadMore={loading}
            dataSource={langu}
            renderItem={item => <Popover><List.Item className={styles.antlistitem} onClick={() => TranslateTo(item)}>{item}</List.Item></Popover>}
        >
        </List>
    </Modal>;
}
