
import { useState, useEffect } from "react";
import { EventNames } from "../domain/events/EventNames";
import { appEmit, appSubscribe } from "../domain/wiring/AppDomain";

let staticCurrentPathName = "";

export function setCurrentPathName(pathName: string) {
    staticCurrentPathName = pathName;
    appEmit(EventNames.pathNameChanged, staticCurrentPathName);
}

export function useCurrentPathName() {
    const [currentPathName, setCurrentPathName] = useState<string>(staticCurrentPathName);
    useEffect(() => {
        if (currentPathName !== staticCurrentPathName) {
            setCurrentPathName(staticCurrentPathName);
        }
        const subscription = appSubscribe<string>(EventNames.pathNameChanged, e => setCurrentPathName(e));
        return () => subscription.unsubscribe();
    }, [currentPathName]);
    return currentPathName;
}
