export enum EventNames {
    loggedIn = "loggedIn",
    languageChanged = "languageChanged",
    uploadDone = "uploadDone",
    reloadAbsences = "reloadAbsences",
    reloadAttentiondays = "reloadAttentiondays",
    InitialiseQuestion = "InitialiseQuestion",
    validationError = "validationError",
    setSelectedGroup = "setSelectedGroup",
    loggedOut = "loggedOut",
    search = "search",
    navigate = "navigate",
    pathNameChanged = "pathNameChanged"
}
