import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConfigProvider, Empty } from "antd";
import nlNL from "antd/lib/locale/nl_NL";
import "moment";
import "moment/locale/nl";
import React, { ErrorInfo } from "react";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import { HashRouter } from "react-router-dom";
import shortid from "shortid";
import { BaseComponent } from "./components/core/BaseComponent/BaseComponent";
import { MenuToggleButton } from "./components/core/NavMenu/MenuToggleButton";
import { EventNames } from "./domain/events/EventNames";
import { appDomain } from "./domain/wiring/AppDomain";
import { RoutingWrapper } from "./RoutingWrapper";
import { Wiring } from "./wiring/Wiring";

export const wiring = new Wiring();

function ErrorFallback(props: FallbackProps) {
    return (
        <div role="alert" className="error-view">
            <h2>{props.error?.message}</h2>
            <pre>{props.error?.stack}</pre>
            <div className="button-group">
                <div className="action-button blue" onClick={props.resetErrorBoundary}> <FontAwesomeIcon icon="redo" /> Try again</div>
                <div className="action-button orange" onClick={() => window.location.reload()}> <FontAwesomeIcon icon="recycle" /> Refresh</div>
            </div>
        </div>
    );
}

export class App extends BaseComponent {
    private languageLoaded: boolean = false;
    constructor(props: {}) {
        super(props);
        this.subscribe(EventNames.loggedIn, this.onLoginLogout.bind(this));
        this.subscribe(EventNames.loggedOut, this.onLoginLogout.bind(this));
        this.subscribe(EventNames.uploadDone, this.onUploadDone.bind(this));
    }

    componentDidMount() {
        appDomain.IRequestTranslation.fromCacheOrDefault().then(() => {
            this.languageLoaded = true;
            this.forceUpdate();
        });
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
        console.warn("componentDidCatch", error, errorInfo);
    }

    onLoginLogout = () => this.setStateWhenMounted({}); // Force re-render
    onUploadDone = () => this.setStateWhenMounted({}); // Force re-render

    onLanguageChanged() {
        // when multi-language, set moment+antd locale here
        this.setStateWhenMounted({}); // Force re-render
    }

    render() {
        return (
            <ErrorBoundary
                FallbackComponent={ErrorFallback}
                onReset={() => { }}>
                <ConfigProvider locale={nlNL} renderEmpty={() => <Empty />}>
                    <div key={shortid.generate()} className="device-wrapper">
                        <main>
                            <HashRouter>
                                <RoutingWrapper key="routing-wrapper" />
                            </HashRouter>
                        </main>
                        {this.getMenuButton()}
                    </div>
                </ConfigProvider>
            </ErrorBoundary>
        );
    }

    private getMenuButton() {
        if (appDomain.IAuthenticationService.isAuthenticated()) {
            return <MenuToggleButton />;
        }
        return <></>;
    }
}

export default App;
