import { DeleteOutlined } from "@ant-design/icons";
import { Button } from "antd";
import React from "react";
import { translate } from "../../domain/wiring/AppDomain";

interface IProps {
    fn: () => Promise<any>
}

export const DeleteAction = (props: IProps) => {
    return (
        <Button title={translate("action.delete")} onClick={(event) => {
            event.stopPropagation();
            props.fn();
        }} style={{ backgroundColor: "white" }}><DeleteOutlined style={{ color: "red" }}></DeleteOutlined></Button>
    );
};

export const OnlyWhen = (value: boolean, fn: () => JSX.Element) => value ? fn() : <></>;
export const OnlyWhenFalse = (value: boolean, fn: () => JSX.Element) => !value ? fn() : <></>;
