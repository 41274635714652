/* eslint-disable react-hooks/exhaustive-deps */
import { motion, usePresence } from "framer-motion";
import React, { useEffect, useRef } from "react";

function getFadeAnimation() {
    return {
        initial: {
            zIndex: 100,
            opacity: 0,
            transitionEnd: {
                display: "block"
            }
        },
        visible: {
            zIndex: 10,
            opacity: 1,
            display: "block"
        },
        hidden: {
            zIndex: 1,
            opacity: 1,
            transitionEnd: {
                display: "none"
            }
        }
    };
}

interface AnimationWrapperProps {
    animation?: any,
    children: any,
    className: string;
    routeId: string;
    routePath: string;
}

export function AnimationWrapper(props: AnimationWrapperProps) {
    const { className, routeId, routePath } = props;
    // const animation = getSlideAnimation(EnumDirection.Top);
    const animation = getFadeAnimation();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const div = ref.current;
        if (!div) return;
        div.setAttribute("data-id", routeId);
        div.setAttribute("data-path", routePath);
    }, [ref]);

    // Fix issue where elements are not removed after animation to hidden
    // see: https://www.framer.com/api/motion/utilities/#usepresence
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        if (!isPresent && safeToRemove) {
            setTimeout(() => safeToRemove(), 2000);
        }
    }, [isPresent, safeToRemove]);

    return (
        <motion.div ref={ref} initial="initial" animate="visible" variants={animation} exit="hidden" transition={{ ease: "easeIn", duration: 0.30, delay: 0.20 }} className={className}>
            {props.children}
        </motion.div >
    );
}
