import { motion, usePresence, Variants } from "framer-motion";
import React, { useEffect } from "react";

export interface IRect {
    width: number;
    height: number;
}

export enum EnumSlideDirection {
    Left,
    Right,
    Top,
    Bottom,
}

function getSlideAnimation(rect: IRect, direction: EnumSlideDirection): Variants {
    const width = rect.width;
    const height = rect.height;
    let xPos = 0;
    let yPos = 0;

    if (direction === EnumSlideDirection.Left) {
        xPos = width;
    }
    if (direction === EnumSlideDirection.Right) {
        xPos = -width;
    }

    if (direction === EnumSlideDirection.Top) {
        yPos = height;
    }
    if (direction === EnumSlideDirection.Bottom) {
        yPos = -height;
    }

    return {
        initial: {
            zIndex: 100,
            x: xPos,
            y: yPos,
            opacity: 1,
            transitionEnd: {
                display: "none"
            }
        },
        visible: {
            zIndex: 10,
            x: 0,
            y: 0,
            opacity: 1,
            display: "block"
        },
        hidden: {
            zIndex: 1,
            x: 0,
            y: 0,
            opacity: 0,
            transitionEnd: {
                display: "none"
            }
        }
    };
}

interface AnimationWrapperProps {
    direction: EnumSlideDirection;
    rect: IRect;
    animate: string;
    className?: string;
    children: any;
}

export function SlideAnimationWrapper(props: AnimationWrapperProps) {
    const animation = getSlideAnimation(props.rect, props.direction);

    // Fix issue where elements are not removed after animation to hidden
    // see: https://www.framer.com/api/motion/utilities/#usepresence
    const [isPresent, safeToRemove] = usePresence();
    useEffect(() => {
        if (!isPresent && safeToRemove) {
            setTimeout(() => safeToRemove(), 2000);
        }
    }, [isPresent, safeToRemove]);

    return (
        <motion.div initial="initial" animate={props.animate} variants={animation} exit="hidden" transition={{ ease: "easeIn", duration: 0.15, delay: 0.05 }} className={props.className}>
            {props.children}
        </motion.div>
    );
}
