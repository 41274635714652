import { Button, Form, Input, List, Modal, Result } from "antd";
import { appDependencies } from "../../../domain/wiring/AppDependencies";
import React, { useCallback, useState } from "react";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";
import { PasswordModel } from "./PasswordModel";

export function ChangePasswordPopUp(props: { visible: boolean, hide: () => void }) {
    const [form] = Form.useForm();

    async function ChangePassword (passwordData: PasswordModel) : Promise<boolean> {
        return await appDomain.IRequestPasswordChange.requestPasswordChange(passwordData.oldPassword, passwordData.newPassword, passwordData.confirmPassword);
    }

    const onFinish = (values: any) => {
        var passwordData = new PasswordModel({ oldPassword: values.oldPassword, newPassword: values.newPassword, confirmPassword: values.confirmPassword });

        ChangePassword(passwordData).then((result: boolean) => {
            if (result) {
                showModel(translate("passwordChanged"));
            } else {
                showModel(translate("invalidUserCredentials"));
            }
        });
    };

    function showModel(message : String) {
        return Modal.success({
            title: message
        });
    }

    return (<Modal title={translate("changePassword")} visible={props.visible}
        okButtonProps={{ form: "category-editor-form", htmlType: "submit" }}
        okText={translate("changePassword")} onOk={() => { form.submit(); props.hide(); }} cancelText={translate("cancel")} onCancel={() => { props.hide(); }}>

        {/* <p className={styles.labels}>Huidig wachtwoord</p> */}
        <Form
            name="basic"
            onFinish={onFinish}
            initialValues={{ remember: false }}
            form={form}
        >
            <Form.Item
                name="oldPassword"
                rules={[{ required: true, message: "Please input your username!" }]}
            >
                <Input.Password placeholder={translate("oldPassword")}/>
            </Form.Item>

            {/* <p className={styles.labels}>Nieuw wachtwoord</p> */}
            <Form.Item
                name="newPassword"
                rules={[{ required: true, message: "Please input your password!" }]}
            >
                <Input.Password placeholder={translate("forgotPassword")}/>
            </Form.Item>

            {/* <p className={styles.labels}>Nieuw wachtwoord</p> */}
            <Form.Item
                name="confirmPassword"
                rules={[{ required: true, message: "Please input your password!" }]}
            >
                <Input.Password placeholder={translate("forgotPassword")}/>
            </Form.Item>
        </Form>
    </Modal>);
}
