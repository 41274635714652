import { IRestApi } from "@dna/rest-api-helper";
import { IToken } from "../../domain/authentication/IToken";
import { IAuthenticationRepository } from "../../domain/authentication/IAuthenticationRepository";
import { appDependencies } from "../../domain/wiring/AppDependencies";
import { ITaskResult } from "../../domain/core/ITaskResult";

export class AuthenticationRepository implements IAuthenticationRepository {
    private RestApi: IRestApi;
    constructor() {
        this.RestApi = appDependencies.IRestApi;
    }

    async getToken(username: string, password: string): Promise<ITaskResult<IToken>> {
        const response = await this.RestApi.request("api/token").data({ username: username, password: password }).post();
        return response.data as ITaskResult<IToken>;
    }

    async refreshToken(refreshToken: string): Promise<IToken> {
        const response = await this.RestApi
            .request("api/token/refresh")
            .addHeader("Authorization", `Bearer ${refreshToken}`)
            .post();
        return response.data as IToken;
    }
}
