import { appDependencies } from "../wiring/AppDependencies";
import { appDomain } from "../wiring/AppDomain";
import { IRequestVersionInfo } from "./IRequestVersionInfo";
import { IVersionInfo } from "./IVersionInfo";

export class VersionService implements IRequestVersionInfo {
    requestVersionInfo(): Promise<IVersionInfo> {
        return appDependencies.IVersionInfoRepository.getVersionInfo();
    }

    async getAllInfoForApp(): Promise<Map<string, string>> {
        const translate = appDomain.IRequestTranslation.translate;
        const map = new Map<string, string>();

        try {
            const { appVersion, webApiVersion, environment } = await this.requestVersionInfo();
            map.set(translate("app.version"), appVersion);
            map.set(translate("app.api-version"), webApiVersion);
            map.set(translate("app.environment"), environment);
        } catch (e) {
            console.warn(e);
        }
        map.set(translate("app.useragent"), navigator.userAgent);
        map.set(translate("app.api-host"), (window as any).config.WebApiUrl);

        return map;
    }
}
