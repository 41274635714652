import { IPortResolver } from "../domain/wiring/IPortResolver";

export class PortResolver implements IPortResolver {
    private _container: any = {};

    register<T>(name: string, fn: () => T) {
        this._container[name] = fn;
    }

    registerInstance<T>(name: string, fn: () => T) {
        let instance: any = null;
        const getInstance = (func: Function) => {
            return () => {
                if (!instance) {
                    instance = func();
                }
                return instance;
            };
        };
        this._container[name] = getInstance(fn);
    }

    resolve<T>(name: string): T {
        if (name in this._container && typeof this._container[name] === "function") {
            return this._container[name]() as T;
        }
        throw new Error("PortResolver can not resolve " + name);
    }
}
