import { Button, DatePicker, Input } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { LoadingIndicator } from "../../../components/basic/LoadingIndicator/LoadingIndicator";
import { ISalesTransactionLine } from "../../../domain/salesTransactions/ISalesTransaction";
import { appDependencies } from "../../../domain/wiring/AppDependencies";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";
import styles from "../../salesTransaction/list/SalesTransactionList.module.css";
import { SalesTransactionBlock } from "../block/SalesTransactionBlock";

export function SalesTransactionList() {
    const [salesTransactionLines, setSalesTransactionLines] = useState<ISalesTransactionLine[]>([]);
    const [loaded, setLoaded] = useState<boolean>(false);

    const [searchText, setSearchText] = useState("");
    const [selectedDates, setSelectedDates] = useState([
        { firstDate: new Date(new Date().setMonth(-2)) },
        { lastDate: new Date() }
    ]);

    const FetchData = async (firstDate?: Date, lastDate?: Date) => {
        const data = await appDomain.ISalesTransactionService.getAllNoCache(firstDate, lastDate);
        const newData = onSearch(data);
        setSalesTransactionLines(newData);
    };

    const onSearch = (data: ISalesTransactionLine[]) => {
        return data.filter(x => onFilter(x, searchText));
    };

    const onFilter = (line: ISalesTransactionLine, value: string) => {
        value = value.toLocaleLowerCase();
        if (line.date.toLocaleLowerCase().includes(value)) return true;
        if (line.debtor.toLocaleLowerCase().includes(value)) return true;
        if (line.text.toLocaleLowerCase().includes(value)) return true;
        if (line.count.toString().includes(value)) return true;
        if (line.price.toString().includes(value)) return true;
        if (line.totalPrice.toString().includes(value)) return true;
    };

    useEffect(() => {
        setLoaded(false);
        const init = async () => {
            await FetchData(selectedDates[0].firstDate, selectedDates[1].lastDate);
            setLoaded(true);
        };
        init();
    }, []);

    if (!loaded) {
        return <LoadingIndicator isLoading={true} />;
    }

    const dateFormat = "DD-MM-YYYY";

    const { RangePicker } = DatePicker;
    const { Search } = Input;

    const filterOnDate = async (eventArg: any) => {
        if (eventArg === null) { return FetchData(); }

        const newDates = [{ firstDate: new Date(eventArg[0]) }, { lastDate: new Date(eventArg[1]) }];

        setSelectedDates([...newDates]);
        setLoaded(false);
        await FetchData(new Date(eventArg[0]), new Date(eventArg[1]));
        setLoaded(true);
    };

    const exportToExcel = () => {
        appDependencies.ISalesTransactionRepository.getSalesArticlesAsExcel(salesTransactionLines);
    };

    const onSearchChange = (value: string) => {
        setSearchText(value);

        if (value !== "") {
            var results = salesTransactionLines.filter(x => onFilter(x, value));
            setSalesTransactionLines(results);
            return;
        }

        FetchData();
    };

    return <div>
        <div className={styles.header}>
            <div className={styles.filter}>
                <RangePicker defaultValue={[moment(selectedDates[0].firstDate, dateFormat), moment(selectedDates[1].lastDate, dateFormat)]} format={dateFormat} onChange={value => filterOnDate(value)} />
            </div>
            <div className={styles.search}>
                <Search
                    placeholder="..."
                    allowClear
                    onChange={(event) => onSearchChange(event.target.value)}
                />
            </div>
            <div className={styles.exportButton}>
                <Button type="primary" onClick={() => { exportToExcel(); }}>{translate("export")}</Button>
            </div>
        </div>
        <div>
            {
                SalesTransactionBlock({
                    salesTransactionLine: salesTransactionLines,
                    key: "salesTransactionLines"
                })
            }
        </div>
    </div>;
}
