import { PlusOutlined } from "@ant-design/icons";
import { message, Modal, Upload } from "antd";
import { RcFile, UploadChangeParam, UploadFile } from "antd/lib/upload/interface";
import React, { useState } from "react";
import { appDependencies } from "../../../domain/wiring/AppDependencies";
import { appDomain, translate } from "../../../domain/wiring/AppDomain";
import { getArticleImageAddUrl, getArticleImageUrl, getImagesAddUrl } from "../../../helpers/imageHelpers";
import { useAsyncFn } from "../../../helpers/useAsyncFn";

function getBase64(file: RcFile): Promise<string> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(String(reader.result));
        reader.onerror = error => reject(error);
    });
}

interface IProps {
    articleId: number;
    attachmentKeys: string[];
}

export function CustomFileUpload(props: IProps) {
    const [previewVisible, setPreviewVisible] = useState<boolean>(false);
    const [previewImage, setPreviewImage] = useState<string>();
    const [previewTitle, setPreviewTitle] = useState<string>();
    const [fileList, setFileList] = useState<UploadFile<File>[]>([]);

    useAsyncFn(async () => {
        const existingImages = props.attachmentKeys.map(x => {
            return {
                uid: x,
                name: "",
                url: getArticleImageUrl(props.articleId, x)
            };
        });
        setFileList(existingImages);
    }, []);

    const handleCancel = () => setPreviewVisible(false);

    const handlePreview = async (file: UploadFile<File>) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj!);
        }
        const title = file.name || String(file.url).substring(String(file.url).lastIndexOf("/") + 1);
        setPreviewImage(file.url || file.preview);
        setPreviewVisible(true);
        setPreviewTitle(title);
    };

    const handleChange = (x: UploadChangeParam<File>) => {
        setFileList(x.fileList);
        if (!(x.file as any).url) {
            (x.file as any).url = getArticleImageUrl(props.articleId, (x.file as any).response);
        }
    };

    const beforeUpload = (file: RcFile) => {
        const acceptingFileTypes = ["image/jpeg", "image/png", "image/webp", "image/gif", "image/avif", "image/apng"];
        const isValidImage = !!acceptingFileTypes.find(x => x === file.type);
        if (!isValidImage) {
            message.error(translate("invalidFileFormat"));
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            message.error(translate("MaxFileSizeLimit"));
        }
        return isValidImage && isLt2M;
    };
    const onRemove = async (e: UploadFile<File>, articleId: number) => {
        const confirmResponse = appDomain.IRequestNotify.notify({
            title: translate("remove") + "?",
            showConfirmButton: true,
            confirmButtonText: translate("yes"),
            cancelButtonText: translate("no"),
            showCancelButton: true
        });
        if ((await confirmResponse).isConfirmed) {
            const response = await appDependencies.IRestApi.request(`/api/Article/image/${articleId}/${e.uid}`).delete();
            return response.status === 204;
        }
        return false;
    };

    const uploadImagesGlobarOrPerArticle = (articleId: number) => {
        return getArticleImageAddUrl(props.articleId);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>{translate("upload")}</div>
        </div>
    );

    return <div style={{ marginTop: "1rem" }}>
        <Upload
            multiple
            listType="picture-card"
            fileList={fileList}
            onPreview={handlePreview}
            onChange={handleChange}
            beforeUpload={beforeUpload}
            onRemove={(e: any) => onRemove(e, props.articleId)}
            action={(file: any) => uploadImagesGlobarOrPerArticle(props.articleId)}
        >
            {fileList.length >= 8 ? null : uploadButton}
        </Upload>
        <Modal
            visible={previewVisible}
            title={previewTitle}
            footer={null}
            onCancel={handleCancel}
        >
            <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
    </div>;
}
