export enum LocalStorageKeys {
    translations = "translations",
    currentLanguage = "currentLanguage",
    cart = "cart",
    userRights = "userRights",
    fileToken = "fileToken",
    redirectToUrlAfterLogin = "redirectToUrlAfterLogin",
    LastCheckedArticleLine = "lastCheckedArticleLine",
    authToken = "authToken",

    lastCustomerSearch = "lastCustomerSearch",
    lastSelectedCustomer = "lastSelectedCustomer",
    resultViewState = "resultViewState",

    lastUserName = "lastUserName",
    filterInactive = "filterInactive",
}
