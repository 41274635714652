import { CachedPromise } from "../../helpers/CachedPromise";
import { ITaskResult } from "../core/ITaskResult";
import { appDependencies } from "../wiring/AppDependencies";
import { IArticle } from "./IArticle";
import { IArticleService } from "./IArticleService";
import { IIdLabel } from "./IIdLabel";

export class ArticleService implements IArticleService {
    private readonly articles = new CachedPromise<IArticle[]>(() => appDependencies.IArticleRepository.getAll(), 1); // short caching
    private readonly articleGroups = new CachedPromise<IIdLabel[]>(() => appDependencies.IArticleRepository.getArticleGroups(), 1); // short caching
    private readonly alternativeItems = new CachedPromise<IIdLabel[]>(() => appDependencies.IArticleRepository.getAlternativeItemOptions(), 1); // short caching

    private readonly countries = new CachedPromise<IIdLabel[]>(() => appDependencies.IArticleRepository.getCountries());
    private readonly units = new CachedPromise<IIdLabel[]>(() => appDependencies.IArticleRepository.getUnits());
    private readonly currencies = new CachedPromise<IIdLabel[]>(() => appDependencies.IArticleRepository.getCurrencies());

    async createOrUpdateArticle(article: IArticle): Promise<ITaskResult<boolean>> {
        this.articles.setExpired();
        this.alternativeItems.setExpired();

        return await appDependencies.IArticleRepository.createOrUpdateArticle(await this.convertAllEnumsToText(article));
    }

    getAll(): Promise<IArticle[]> {
        return this.articles.load();
    }

    async getByNumber(articleNumber: string): Promise<IArticle> {
        return await appDependencies.IArticleRepository.getByNumber(articleNumber);
    }

    async convertAllEnumsToText(article: IArticle) {
        var allGroups = await this.getArticleGroups();
        var allCountries = await this.getCountries();
        var allUnits = await this.getUnits();
        var allCurrencies = await this.getCurrencies();

        article.articleGroup = allGroups.filter(x => x.id === article.articleGroup)[0].label;
        article.salesPriceCurrency = allCurrencies.filter(x => x.id === article.salesPriceCurrency)[0].label;
        article.unit = allUnits.filter(x => x.id === article.unit)[0].label;
        var countryResult = allCountries.filter(x => x.id === article.countryOfOrigin);
        if (countryResult.length > 0) {
            article.countryOfOrigin = countryResult[0].label;
        }

        return article;
    }

    getArticleGroups(): Promise<IIdLabel[]> {
        return this.articleGroups.load();
    }

    getCountries(): Promise<IIdLabel[]> {
        return this.countries.load();
    }

    getAlternativeItemOptions(): Promise<IIdLabel[]> {
        return this.alternativeItems.load();
    }

    getUnits(): Promise<IIdLabel[]> {
        return this.units.load();
    }

    getCurrencies(): Promise<IIdLabel[]> {
        return this.currencies.load();
    }

    async updateArticleStock(article: IArticle, stockMutationValue: number): Promise<any> {
        await appDependencies.IArticleRepository.updateArticleStock(article, stockMutationValue);
    }

    clearCache() {
        this.articles.setExpired();
    }
}
