import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { AppRoutes } from "../../domain/navigators/AppRoutes";
import { LocalStorageKeys } from "../../domain/storages/LocalStorageKeys";
import { appDomain, translate } from "../../domain/wiring/AppDomain";
import { getLocalStorage, setLocalStorage } from "../../helpers/tsHelper";
import styles from "./LoginForm.module.css";
import { VersionInfo } from "./VersionInfo";

export function LoginForm() {
    const layout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 }
    };

    const buttonLayout = {
        labelCol: { span: 0 },
        wrapperCol: { span: 18 + 6 }
    };
    const [errors, setErrors] = useState<Array<string>>([]);

    const usernameFromLocalStorage = getLocalStorage<string>(LocalStorageKeys.lastUserName);

    const onFinish = async (values: any) => {
        const task = await appDomain.IAuthenticationService.login(values.username, values.password);
        if (task.success) {
            appDomain.INavigator.navigateTo(AppRoutes.home);
            appDomain.IArticleService.clearCache();
            appDomain.IBackorderService.clearCache();
            appDomain.ISalesTransactionService.clearCache();
            setLocalStorage(LocalStorageKeys.lastUserName, values.username);
        } else if (task.validationResults) {
            const validationResults = task.validationResults.map((y) => translate(y.message, y.parameters));
            setErrors(validationResults);
        } else {
            setErrors([translate("fp-login.fail.title"), translate("fp-login.fail.text")]);
        }
    };

    return (
        <div className={styles.loginFormWrapper}>
            <div className={styles.loginFormContent}>
                <h1 className={styles.loginTitle}>{translate("fp-login.title")}</h1>
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{ remember: true, username: usernameFromLocalStorage }}
                    onFinish={onFinish}
                    className={styles.loginForm}
                >
                    <Form.Item
                        labelAlign="left"
                        label={translate("userName")}
                        name="username"
                        rules={[{ required: true, message: translate("fp-login.username.mandatory") }]}
                    >
                        <Input autoFocus />
                    </Form.Item>

                    <Form.Item
                        labelAlign="left"
                        label={translate("password")}
                        name="password"
                        rules={[{ required: true, message: translate("fp-login.password.mandatory") }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <div className={styles.validationResults}>
                        {errors.map(x => <div key={x}>{x}</div>)}
                    </div>
                    <Form.Item {...buttonLayout}>
                        <Button className={styles.loginButton} type="primary" size="large" htmlType="submit">{translate("login")}</Button>
                    </Form.Item>
                    <VersionInfo />
                </Form>
            </div>
        </div>
    );
}
