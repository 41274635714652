import { ISalesTransactionLine } from "../../domain/salesTransactions/ISalesTransaction";
import { ISalesTransactionRepository } from "../../domain/salesTransactions/ISalesTransactionRepository";
import { appDependencies } from "../../domain/wiring/AppDependencies";
import { createQuery, saveBlob } from "../../helpers/tsHelper";

export class SalesTransactionRepository implements ISalesTransactionRepository {
    async getSalesArticlesAsExcel(salesTransactionLines: ISalesTransactionLine[]): Promise<any> {
        const lines = salesTransactionLines;

        const response = await appDependencies.IRestApi.request("api/SalesTransaction/salesTransactionsAsExcel")
            .setResponseType("blob")
            .data({ lines })
            .post();

        saveBlob(response.data, "fairplaza-sales-articles.xlsx");
    }

    async getAll(firstDate: Date, lastDate: Date): Promise<ISalesTransactionLine[]> {
        const query = createQuery({ firstDate, lastDate });

        const salesTransactions = await appDependencies.IRestApi
            .request(`/api/SalesTransaction${query}`)
            .getData<ISalesTransactionLine[]>();

        return salesTransactions;
    }
}
