import { escapeRegExp } from "../../helpers/tsHelper";

interface ISearchItem<T> {
    data: T,
    text: string;
}

export class SearchableList<T> {
    data: ISearchItem<T>[];
    constructor(data: T[], private searchTextFn: (x: T) => string) {
        this.data = data.map(x => ({ data: x, text: this.searchTextFn(x) }));
    }

    search(searchText: string): T[] {
        if (!searchText || typeof searchText !== "string" || searchText.trim() === "") {
            return this.data.map(x => x.data);
        }
        const newSearchText = searchText.replaceAll(".", "");
        const regex = new RegExp(escapeRegExp(newSearchText), "i");
        return this.data.filter(x => regex.test(x.text)).map(x => x.data);
    }
}
