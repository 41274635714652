import {
    CloseOutlined,
    FileJpgOutlined,
    FlagOutlined,
    HomeOutlined,
    KeyOutlined,
    LogoutOutlined,
    TableOutlined
} from "@ant-design/icons";
import { Button, List, Modal, Table } from "antd";
import Menu from "antd/lib/menu";
import React, { useEffect, useState } from "react";
import { ICurrentUser } from "../../../domain/authentication/ICurrentUser";
import { EventNames } from "../../../domain/events/EventNames";
import { AppRoutes } from "../../../domain/navigators/AppRoutes";
import { translateRole } from "../../../domain/user/UserRole";
import { appDomain, appSubscribe, navigateTo, translate } from "../../../domain/wiring/AppDomain";
import { useCurrentPathName } from "../../../helpers/useCurrentPathName";
import { VersionInfo } from "../../login/VersionInfo";
import { ChangePasswordPopUp } from "../Password/ChangePassword";
import { ImportExportExcelPopup } from "../ImportExportExcel/ImportExportExcel";
import { UserInfo } from "../Header/UserInfo";
import { LanguageSelectionPopup } from "../Language/LanguageSelector";
import styles from "./NavMenu.module.css";
import { ImageUploaderPopup } from "../ImageUpload/ImageUploader";
import { AppPopups } from "../../../domain/navigators/AppPopups";

interface IProps {
    closeMenu: () => void;
}

interface IState {
    currentUser?: ICurrentUser
    openMenuKeys: string[];
}

export const NavMenu = (props: IProps) => {
    const [state, setState] = useState<Partial<IState>>({ currentUser: undefined, openMenuKeys: [] });
    const updateState = (newState: Partial<IState>) => setState({ ...state, ...newState });
    const pathName = useCurrentPathName();

    useEffect(() => {
        const run = async () => {
            const user = await appDomain.IAuthenticationService.getCurrentUser();
            updateState({ currentUser: user });
        };
        const subscriptions = [
            appSubscribe(EventNames.loggedIn, run),
            appSubscribe(EventNames.loggedOut, run)
        ];
        run();
        return () => subscriptions.forEach(x => x.unsubscribe());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    let selectedKey: string = pathName;

    const mapping: { [key: string]: string } = {
        // [AppRoutes.userDetail]: AppRoutes.userList,
        // [AppRoutes.userDetailNew]: AppRoutes.userList
    };

    if (mapping[pathName]) {
        selectedKey = mapping[pathName];
    }

    const onOpenChange = (openKeys: React.Key[]) => {
        setState({ openMenuKeys: openKeys as string[] });
    };

    const role = state.currentUser?.userRole;

    const menuClick = (fn?: () => any) => {
        props.closeMenu();
        if (fn) {
            fn();
        }
    };

    const isImageUploadVisible = setMenuOptionVisible(state.currentUser?.imageUpload);
    const isImportExportExcelVisible = setMenuOptionVisible(state.currentUser?.importExportExcel);
    const isBackOrderVisible = setMenuOptionVisible(state.currentUser?.purchaseOverview);
    const isSalesTransactionsVisible = setMenuOptionVisible(state.currentUser?.salesOverview);

    function setMenuOptionVisible(value?: string) : boolean {
        if (value?.toLowerCase() === "true") {
            return false;
        } return true;
    }

    const [languageSelectionVisible, setLanguageSelectionVisible] = useState<boolean>(false);
    const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(false);
    const [imageUploadVisible, setImageUploadVisible] = useState<boolean>(false);
    const [importExportVisable, setImportExportVisible] = useState<boolean>(false);

    const titleImportExportExcel = translate("import") + "/" + translate("export")?.toLocaleLowerCase() + " " + translate("excel")?.toLocaleLowerCase();
    const titleImagesUpload = translate("fp-article.fieldset.images") + " " + translate("importItem");

    function showLanguageSelectionPopup() {
        setLanguageSelectionVisible(true);
    }
    function showChangePasswordPopup() {
        setChangePasswordVisible(true);
    }
    function showImageUploadPopup() {
        setImageUploadVisible(true);
    }
    function showImportExportPopup() {
        setImportExportVisible(true);
    }

    return (
        <>
            <div key="menuUserInfo" className={styles.menuUserInfo}>
                <UserInfo />
            </div>

            <div key="menuCloseButton" className={styles.menuCloseButton}>
                <Button icon={<CloseOutlined />} type="primary" onClick={() => props.closeMenu()} />
            </div>

            <Menu
                style={{ width: 256 }}
                defaultSelectedKeys={[AppRoutes.home]}
                onOpenChange={onOpenChange}
                openKeys={state.openMenuKeys}
                selectedKeys={[selectedKey]}
                mode="inline"
                data-role={translateRole(role ?? 0)}
            >
                <Menu.Item icon={<HomeOutlined />} onClick={() => menuClick(() => navigateTo(AppRoutes.home))} key={AppRoutes.home}>{translate("home")}</Menu.Item>
                <Menu.Item icon={<FlagOutlined />} onClick={() => showLanguageSelectionPopup()} key={AppPopups.languageselector}>{translate("setupLanguage")}</Menu.Item>
                <Menu.Item icon={<KeyOutlined />} onClick={() => showChangePasswordPopup()} key={AppPopups.changepassword}>{translate("changePassword")}</Menu.Item>
                <Menu.Item icon={<FileJpgOutlined />} hidden={isImageUploadVisible} onClick={() => showImageUploadPopup()} key={AppPopups.imagesupload}>{titleImagesUpload}</Menu.Item>
                <Menu.Item icon={<TableOutlined />} hidden={isImportExportExcelVisible} onClick={() => showImportExportPopup()} key={AppPopups.excelimexport}>{titleImportExportExcel}</Menu.Item>
                <Menu.Item icon={<TableOutlined />} hidden={isBackOrderVisible} onClick={() => menuClick(() => navigateTo(AppRoutes.backorder))} key="testBackorder">{translate("creditorOrders")}</Menu.Item>
                <Menu.Item icon={<TableOutlined />} hidden={isSalesTransactionsVisible} onClick={() => menuClick(() => navigateTo(AppRoutes.salesTransaction))} key="testSalesTransaction">{translate("debtorTransactions")}</Menu.Item>
                <Menu.Item icon={<LogoutOutlined />} onClick={() => menuClick(() => appDomain.IAuthenticationService.logoff())} key="10">{translate("logout")}</Menu.Item>
            </Menu>
            <>
                <LanguageSelectionPopup visible={languageSelectionVisible} hide={() => setLanguageSelectionVisible(false)}/>
                <ImageUploaderPopup visible={imageUploadVisible} hide={() => setImageUploadVisible(false)}/>
                <ImportExportExcelPopup visible={importExportVisable} hide={() => setImportExportVisible(false)}/>
                <ChangePasswordPopUp visible={changePasswordVisible} hide={() => setChangePasswordVisible(false)}/>
            </>
            <div key="menuInfoWrapper" className={styles.menuInfoWrapper}>
                <VersionInfo />
            </div>
        </>
    );
};
